import { Alert, type AlertColor } from '@mui/material'
import Snackbar from '@mui/material/Snackbar'

interface AlertsProps {
  open: boolean
  handleClose: () => void
  text: string
  severity: AlertColor
}
const Alerts: React.FC<AlertsProps> = ({
  open,
  handleClose,
  text,
  severity
}: {
  open: boolean
  handleClose: () => void
  text: string
  severity: AlertColor
}) => {
  return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
        >
            <Alert onClose={handleClose} severity={severity} variant="filled">
                {text}
            </Alert>
        </Snackbar>
  )
}

export default Alerts
