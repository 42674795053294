interface ClientProperties {
  data?: any
  method?: string
  token?: string
  headers?: Record<string, unknown>
  body?: string
  customConfig?: Record<string, unknown>
}

export type Client = (
  endpoint: string,
  config: ClientProperties
) => Promise<any>

async function client (
  endpoint: string,
  {
    data,
    method,
    token,
    headers: customHeaders,
    customConfig
  }: ClientProperties
): Promise<Response> {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const config = {
    method,
    body: data ? JSON.stringify(data) : undefined,
    credentials: 'include',
    headers: {
      'Content-Type': data ? 'application/json' : undefined,
      Authorization: token ? `Bearer ${token}` : undefined,
      ...customHeaders
    },
    ...customConfig
  } as RequestInit

  return await window.fetch(`${endpoint}`, config).then(async (response) => {
    const contentType = response.headers.get('content-type')
    const isJson =
      contentType && contentType.includes('application/json')
    if (response.ok) {
      return isJson ? await response.json() : { message: response.text() }
    } else {
      if (isJson) {
        return await Promise.reject(await response.json())
      }
      return await Promise.reject(await response.text())
    }
  })
}

function useClient () {
  return async (endpoint: string, config: ClientProperties) =>
    await client(endpoint, config)
}

export { useClient }
