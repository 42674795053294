import { type SupportedBlockchain, SupportedWallet } from '../../types'
import ledgerWalletProvider from '@baanx/common/network/blockchain/wallet-providers/ledger-wallet-provider'
import { isMobile } from 'react-device-detect'

const getWindow = () => {
    try {
        // if different domain this line will throw. If no iframe (local) window.top = window.self so it works normally
        if (window.top && (window.top as any)?.ethereum) {
            return window.top as unknown as { ethereum; phantom; providers }
        }
    } catch (e) {
        return window as unknown as { ethereum; phantom; providers }
    }
    return window as unknown as { ethereum; phantom; providers }
}

const windowAsAny = getWindow()

export const WALLET_VALIDATORS: {
    [key in SupportedWallet]: (
        arg1?: any,
        arg2?: any
    ) => boolean | Promise<boolean>
} = {
    metamask: function (): boolean {
        // not used
        return true
    },
    phantom: function (): boolean {
        return windowAsAny.phantom?.solana
    },
    ledger: async function (
        type: 'sol' | 'eth',
        network: SupportedBlockchain
    ): Promise<boolean> {
        const provider = ledgerWalletProvider(type)
        if (!provider) return false
        try {
            await provider.connectWallet(network, SupportedWallet.LEDGER)
            return !isMobile
        } catch (e) {
            return false
        }
    },
}

export const WALLET_PROVIDERS: { [key in SupportedWallet]: any } = {
    metamask: function (): any {
       // not used
    },
    phantom: function (): any {
        return windowAsAny.phantom.solana
    },

    ledger: async function (type: 'sol' | 'eth'): Promise<any> {
        return ledgerWalletProvider(type)
    },
}
