import { Card, Grid, Stack, Typography } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
interface DelegateInfoProps {
    title: string
    amount: string
    description: string
}

const DelegateInfo = ({ title, amount, description }: DelegateInfoProps) => (
    <Card variant="outlined" sx={{ borderColor: 'primary.main', backgroundColor: "secondary.light" }}>
        <Grid
            padding={2}
            rowSpacing={1}
            textAlign={'center'}
            container
        >
            <Grid item xs={12}>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                    spacing={1}
                >
                    <Typography fontWeight={700} variant="body1">
                        {title}
                    </Typography>
                        <DoneIcon />
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Typography fontWeight={700} variant="h5">
                    {amount}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body2">{description}</Typography>
            </Grid>
        </Grid>
    </Card>
)

export default DelegateInfo
