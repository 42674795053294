import {
    Button,
    Grid,
    Typography,
    Tooltip,
    Stack,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import NavigateNext from '@mui/icons-material/NavigateNext'
import NavigateBefore from '@mui/icons-material/NavigateBefore'
import Loading from '../loading/Loading'
import { Blockchains } from '../blockchain/Utils'

import {
    type SupportedBlockchain,
    SupportedWallet,
} from '../../types'
import { useLocation } from 'react-router-dom'
import { ResultModalType, useModal } from '../../contexts/ModalContext'
import {
    PostErrorMessage,
    PostOperationType,
    getErrorMessage,
    handleError,
} from '../../error'
import Address from '../address'
import { usePostWalletToken } from '@baanx/common/network/api/token'
import usePostMessage from '../../hooks/usePostMessage'
import config from '../../config'
import useBlockchain from '../../hooks/useBlockchain'
let strictInitialized = false

const LedgerAccounts = () => {
    const location = useLocation();
    const {
        selectedNetwork,
    } = location.state
    const {
        disconnect,
        setLedgerIndex,
        setHasSelectLedgerAddress,
        getAccount,
        getLedgerAddresses
    } = useBlockchain()
    const [ledgerAddresses, setLedgerAddr] = useState<string[]>([])
    const [initOffset, setInitOffset] = useState<number>(0)
    const [ledgerLoading, setLedgerLoading] = useState<boolean>(false)
    const quantityByPage = 5

    const { toggleResult } = useModal()
    const { mutateAsync: postWalletToken } = usePostWalletToken(config)

    const { postSuccessMessage, postErrorMessage } = usePostMessage(
        PostOperationType.WALLET_CONNECT
    )

    const handleNavigation = async (ledgerIndex: number) => {


            if (selectedNetwork == null) return

            try {
                const address = await getAccount(
                    selectedNetwork,
                    SupportedWallet.LEDGER,
                    true,
                    undefined,
                    ledgerIndex
                )
                const walletInfo = await postWalletToken({
                    address,
                    provider: SupportedWallet.LEDGER,
                    index: ledgerIndex,
                })

                if (!walletInfo) throw Error('Could not set wallet info')

                postSuccessMessage({
                    token: walletInfo.token,
                })

            } catch (error) {
                toggleResult(
                    true,
                    'Ledger error',
                    `Could not set wallet info. Reason: ${getErrorMessage(
                        error
                    )}`,
                    ResultModalType.ERROR
                )
                postErrorMessage(
                    PostErrorMessage.WALLET_CONNECTION_ERROR,
                    error
                )
                handleError(error)
            }

    }

    const nextHandler = async () => {
        try {
            await refreshContent(initOffset + quantityByPage)
            setInitOffset((prevOffset) => {
                return prevOffset + quantityByPage
            })
        } catch (error) {
            toggleResult(
                true,
                'Ledger error',
                `Could not connect to your ledger device. Reason: ${getErrorMessage(
                    error
                )}`,
                ResultModalType.ERROR
            )
        }
    }

    const lastHandler = async () => {
        try {
            await refreshContent(initOffset - quantityByPage)
            setInitOffset((prevOffset) => {
                return prevOffset - quantityByPage
            })
        } catch (error) {
            throw Error('Error connecting with ledger device.')
        }
    }

    const setIndexHandler = async (index: number) => {
        setLedgerIndex(index)
        setHasSelectLedgerAddress(true)
        await handleNavigation(index)
    }

    const refreshContent = useCallback(
        async (offset: number) => {
            setLedgerLoading(true)
            await getLedgerAddresses(
                    selectedNetwork === 'solana',
                    offset,
                    offset + quantityByPage - 1
                )
                .then((addresses) => {
                    setLedgerAddr(addresses)
                })
                .finally(() => {
                    setLedgerLoading(false)
                })
        },
        [getLedgerAddresses, selectedNetwork]
    )

    useEffect(() => {
        if (!strictInitialized && ledgerAddresses.length === 0) {
            strictInitialized = true
            refreshContent(initOffset)
                .catch((error) => {
                    toggleResult(
                        true,
                        'Ledger error',
                        `Could not connect to your ledger device. ${getErrorMessage(
                            error
                        )}`,
                        ResultModalType.ERROR
                    )
                    disconnect()
                    handleError(error)
                })
                .finally(() => {
                    strictInitialized = false
                })
        }

        return () => {}
    }, [
        disconnect,
        initOffset,
        ledgerAddresses.length,
        refreshContent,
        toggleResult,
    ])

    return (
        <Grid xs={12} item>
            <Grid p={4}>
                <Grid container item xs={12} textAlign={'center'}>
                    <Grid item xs={12}>
                        {
                            Blockchains[selectedNetwork as SupportedBlockchain]
                                .icon
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            <b>
                                {
                                    Blockchains[
                                        selectedNetwork as SupportedBlockchain
                                    ].name
                                }
                            </b>
                        </Typography>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12}
                    textAlign={'center'}
                    marginTop={2}
                    marginBottom={2}
                >
                    <Typography variant="body1">
                        Select the Ledger account you want to use
                    </Typography>
                </Grid>
                {ledgerLoading && <Loading />}
                <Stack spacing={2}>
                    {!ledgerLoading &&
                        ledgerAddresses.map((address, index) => (
                            <Grid
                                container
                                item
                                xs={12}
                                key={`${address}-${index}`}
                            >
                                <Grid item xs={1}>
                                    <Typography align="left" variant="caption">
                                        {index + 1 + initOffset}
                                    </Typography>
                                </Grid>
                                <Grid item xs={11}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={setIndexHandler.bind(
                                            null,
                                            index
                                        )}
                                        fullWidth
                                    >
                                        <Tooltip title={address}>
                                            <>
                                                <Address text={address} />
                                            </>
                                        </Tooltip>
                                    </Button>
                                </Grid>
                            </Grid>
                        ))}
                </Stack>

                <Grid container mt={2} item xs={12} spacing={2}>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            disabled={initOffset === 0 || ledgerLoading}
                            onClick={lastHandler}
                            startIcon={<NavigateBefore />}
                        >
                            Previous
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={nextHandler}
                            disabled={ledgerLoading}
                            endIcon={<NavigateNext />}
                        >
                            Next
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default LedgerAccounts
