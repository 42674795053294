import { Tooltip, type TooltipProps, styled, tooltipClasses } from "@mui/material";

// From MUI DOCS: https://mui.com/material-ui/react-tooltip/#controlled-tooltips
const CustomWidthTooltip: React.FC<TooltipProps> = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: "50vw",
    },
  });

  export default CustomWidthTooltip