import { createTheme, CssBaseline, type PaletteMode, type ThemeOptions, ThemeProvider } from '@mui/material'
import React from 'react'
declare module '@mui/material/styles' {
    interface TypographyVariants {
        warning: React.CSSProperties
        warningSm: React.CSSProperties
    }
    interface TypographyVariantsOptions {
        warning?: React.CSSProperties
        warningSm?: React.CSSProperties
    }
    interface Theme {
        status: {
            danger: string;
        };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        warning: true
        warningSm: true
    }
}

declare module '@mui/material/styles' {
    interface Palette {
        secondaryText: Palette['primary'];
    }
    interface PaletteOptions {
        secondaryText: PaletteOptions['primary'];
    }
  }
const ColorModeContext = React.createContext({ toggleColorMode: () => { } });

const fontFamily = 'Euclid Circular B'
const themeOptions: ThemeOptions = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1360,
            xl: 1536,
        },
    },
    typography: {
        fontFamily,
        /**
         * New custom Typography variants don't use the default font family or other inherited props.
         * We need to define it again.
         **/
        warning: {
            color: 'rgba(246, 133, 26, 1)',
            fontSize: '1rem',
            fontWeight: 700,
            flexGrow: 1,
            fontFamily,
            display: 'block',
        },
        warningSm: {
            fontWeight: 500,
            fontSize: '0.8rem',
            fontFamily,
            color: 'rgba(246, 133, 26, 1)',
            display: 'block',
        },
        h6: {
            fontWeight: 400,
            fontSize: '1rem',
        },
        h5: {
            fontWeight: 700,
        },
        h4: {
            fontWeight: 700,
        },
    },
    // palette: created in makeThemeOptions
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: '1rem',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
              root: {
                textTransform: 'none',
                boxShadow: 'none',
                '&:hover': {
                  boxShadow: 'none',
                },
                '&:active': {
                  boxShadow: 'none',
                },
                '&:focus': {
                  boxShadow: 'none',
                },
              },
              contained: {
                borderRadius: '50px',
                height: '3.25rem',
              },
              outlined: {
                borderRadius: '1rem',
              },
              text: {
                borderRadius: '1rem',
              },
            },
          },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: '0.75rem',
                },
            },
        },
    },
    // TODO restore these
    // MuiTypography: {
    //     styleOverrides: {
    //         h5: {
    //             [theme.breakpoints.up('xs')]: {
    //                 fontSize: "1.125rem"
    //             },
    //             [theme.breakpoints.up('sm')]: {
    //                 fontSize: "1.5rem"
    //             },
    //         },
    //         h4: {
    //             [theme.breakpoints.up('xs')]: {
    //                 fontSize: "1.325rem"
    //             },
    //             [theme.breakpoints.up('sm')]: {
    //                 fontSize: "2.125rem"
    //             },
    //         }
    //     },
    // }
}

function makeThemeOptions(mode: PaletteMode): ThemeOptions {
    const options: ThemeOptions = { ...themeOptions }
    if (mode === 'light') {
        options.palette = {
            mode,
            primary: {
                main: '#0376C9',
            },
            success: {
                main: '#2bc18a',
            },
            secondary: {
                main: '#24272A',
                light: '#f2f4f6',
            },
            warning: {
                main: 'rgba(246, 133, 26, 1)',
                light: 'rgba(254, 251, 237, 1)',
            },
            background: {
                default: '#FFFFFF',
            },
            secondaryText: {
                main: "#424242"
            }
        }
    } else {
        options.palette = {
            mode: 'dark',
            primary: {
                main: '#0376C9',
            },
            success: {
                main: '#2bc18a',
            },
            secondary: {
                main: '#F8F8F8',
                light: '#121212',
            },
            background: {
                default: 'black',
            },
            secondaryText: {
                main: "#ffffff"
            }
        }
    }

    // Typography caption variant color
    options.typography = {
        ...options.typography,
        caption: {
            color: (options.palette.secondaryText as any).main,
            display: 'block',
        },
    }
    return options
}

    export default function FoxTheme({ children }: { children: JSX.Element }) {
        const tokenUrl = new URLSearchParams(location.search).get('theme') ?? 'light'
        const themeMode = tokenUrl === 'light' ? 'light' : 'dark'

        const [mode, setMode] = React.useState<'light' | 'dark'>(themeMode)
        const colorMode = React.useMemo(
            () => ({
                toggleColorMode: () => {
                    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
                },
            }),
            [],
        )

        const theme = React.useMemo(
            () => createTheme(makeThemeOptions(mode)),
            [mode],
        )

        return (
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <CssBaseline>
                        {/* <Button fullWidth onClick={colorMode.toggleColorMode}>Toggle</Button> */}
                        {children}
                    </CssBaseline>
                </ThemeProvider>
            </ColorModeContext.Provider>
        )
    }

    export {
        FoxTheme,
    }
