import { Button, Divider, Stack, Typography } from '@mui/material'
import { type ReactElement, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { NavigateLocations } from '../../types'
import { useWalletToken } from '@baanx/common/network/api/token'
import config from '../../config'

interface SessionData {
    address: string
    provider: string
    token: string
}

const AnrkMock = (): ReactElement => {
    const navigate = useNavigate()
    // @ts-expect-error - let's ignore this for this mock page
    const [sessionData, setSessionData] = useState<SessionData>(null)
    const [signatureVerified, setSignatureVerified] = useState(false)
    const { refetch: getWalletToken } = useWalletToken(config)

    const urlParams = new URLSearchParams(window.location.search)
    const signature = urlParams.get('signature')
    const walletToken = urlParams.get('walletToken')
    const status = urlParams.get('status')
    const swapToken = urlParams.get('swapToken')

    const goToConnectWalletHandler = (): void => {
        navigate(NavigateLocations.CONNECT_WALLET)
    }

    const goToDelegationSwapHandler = (): void => {
        navigate(`${NavigateLocations.DELEGATE_SWAP}?swapToken=SWPTKN01`)
    }

    const goToSignHandler = (): void => {
        navigate(
            `${NavigateLocations.SIGN}?swapToken=SWPTKN01&skipConfirmation=true`
        )
    }

    useEffect(() => {
        if (walletToken) {
            fetch(`${config.api.url}/wallet-sessions/${walletToken}`, {
                headers: {
                    Authorization:
                        'Basic YWJiYWFueDo1OFJUZ1o5eVFLYXM3ZktSWkU5NzVIMzc=',
                    'Content-Type': 'application/json',
                },
            })
                .then(async (response) => {
                    if (!response.ok) {
                        throw new Error('Error fetching wallet session')
                    }
                    return await response.json()
                })
                .then((data) => {
                    setSessionData(data)
                })
                .catch((err) => {
                    console.error(err.message)
                })
        }

        if (signature) {
            fetch(`${config.api.url}/baanx-mocks/swap/SWPTKN01`)
                .then(async (response) => {
                    if (!response.ok) {
                        throw new Error('Error fetching signature')
                    }
                    return await response.json()
                })
                .then(async (data) => {
                    const { data: walletData } = await getWalletToken()
                    // Now we verify the signature
                    const body = {
                        signature,
                        data: {
                            ...data,
                            address: walletData?.wallet.address,
                        },
                    }
                    fetch(`${config.api.url}/signature-validations`, {
                        method: 'POST',
                        headers: {
                            Authorization:
                                'Basic YWJiYWFueDo1OFJUZ1o5eVFLYXM3ZktSWkU5NzVIMzc=',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(body),
                    })
                        .then(async (response) => await response.json())
                        .then((data) => {
                            if (data.signatureVerification === 'OK') {
                                setSignatureVerified(true)
                            } else setSignatureVerified(false)
                        })
                        .catch((error) => {
                            console.error('Error posting data:', error)
                            setSignatureVerified(false)
                        })
                })
                .catch((err) => {
                    console.error(err.message)
                })
        }
    }, [walletToken, signature, getWalletToken])

    return (
        <Stack spacing={3} p={2}>
            <Typography variant="h3" mb={3}>
                ANRK App Mock
            </Typography>

            <Button variant="contained" onClick={goToConnectWalletHandler}>
                Connect Wallet UI
            </Button>

            {sessionData && (
                <>
                    <Typography
                        variant="body1"
                        sx={{ wordBreak: 'break-word' }}
                    >
                        <b>Address:</b>
                        <br />
                        {sessionData.address}
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{ wordBreak: 'break-word' }}
                    >
                        <b>Provider:</b>
                        <br />
                        {sessionData.provider}
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{ wordBreak: 'break-word' }}
                    >
                        <b>Token:</b>
                        <br />
                        {sessionData.token}
                    </Typography>
                </>
            )}

            <Divider color="#FFBB00" />

            <Button variant="contained" onClick={goToDelegationSwapHandler}>
                Swap Delegation UI
            </Button>

            {swapToken && status === 'success' && (
                <Typography variant="body1">Delegation successful.</Typography>
            )}

            <Divider color="#FFBB00" />

            <Button variant="contained" onClick={goToSignHandler}>
                Sign UI
            </Button>

            {signature && (
                <>
                    <Typography
                        variant="body1"
                        sx={{ wordBreak: 'break-word' }}
                    >
                        <b>Signature:</b>
                        <br />
                        {signature}
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{ wordBreak: 'break-word' }}
                    >
                        <b>Signature verification:</b>{' '}
                        {signatureVerified ? 'OK' : 'NOT OK'}
                    </Typography>
                </>
            )}
        </Stack>
    )
}

export default AnrkMock
