import { Typography } from '@mui/material'
import { useState, useRef, useLayoutEffect } from 'react'

// TODO handle window resize
const Address = ({ text }) => {
  const ref = useRef<HTMLInputElement>()
  const [isOverflow, setIsOverflow] = useState(false)
  useLayoutEffect(() => {
    const current = ref.current
    if (current && current.clientWidth < current.scrollWidth) {
      setIsOverflow(true)
    }
  }, [ref])

  return (
    <>
      {!isOverflow &&
          <Typography ref={ref as unknown as ((instance: HTMLSpanElement | null) => void)} noWrap variant="body1">{text}</Typography>
      }
      {isOverflow &&
        <Typography variant="body1">{text.slice(0, 12).concat('...').concat(text.slice(-6))}</Typography>
      }
    </>
  )
}

export default Address
