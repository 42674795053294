import {
    type SupportedToken,
    type SupportedBlockchain,
    type SupportedWallet,
    TOKENS,
} from '../../types'

import { ReactComponent as SmallMetamask } from '../../assets/smallmetamask.svg'
import { ReactComponent as SmallPhantom } from '../../assets/smallphantom.svg'

import { ReactComponent as SmallLedger } from '../../assets/smallledger.svg'
import { ReactComponent as Ledger } from '../../assets/ledger.svg'

import { WALLET_VALIDATORS } from '../../network/blockchain/wallet-utils'

// the BNB svg from cryptocurrency-icons doesn't display correctly for some reason
import { ReactComponent as BnbChainIcon } from '../../assets/smallbnbchain.svg'
import { ReactComponent as BnbIcon } from 'cryptocurrency-icons/svg/icon/bnb.svg'
import { ReactComponent as KncIcon } from 'cryptocurrency-icons/svg/icon/knc.svg'
import { ReactComponent as WavaxIcon } from 'cryptocurrency-icons/svg/icon/avax.svg'

import { ReactComponent as SolanaIcon } from '../../assets/solana-logo.svg'
import { ReactComponent as LineaIcon } from '../../assets/linea-logo.svg'
import { ReactComponent as EtherlinkIcon } from '../../assets/etherlink-logo.svg'
import { ReactComponent as EthIcon } from 'cryptocurrency-icons/svg/icon/eth.svg'
import { ReactComponent as LinkIcon } from 'cryptocurrency-icons/svg/icon/link.svg'

import { ReactComponent as ImxIcon } from '../../assets/imx-logo.svg'
import { ReactComponent as MaticIcon } from 'cryptocurrency-icons/svg/icon/matic.svg'
import { ReactComponent as MkrIcon } from 'cryptocurrency-icons/svg/icon/mkr.svg'
import { ReactComponent as UniIcon } from 'cryptocurrency-icons/svg/icon/uni.svg'
import { ReactComponent as AaveIcon } from 'cryptocurrency-icons/svg/icon/aave.svg'
import { ReactComponent as ShibIcon } from '../../assets/shib-logo.svg'
import { ReactComponent as PepeIcon } from '../../assets/pepe-logo.svg'
import { ReactComponent as ApeIcon } from 'cryptocurrency-icons/svg/icon/ape.svg'
import { ReactComponent as CrvIcon } from 'cryptocurrency-icons/svg/icon/crv.svg'
import { ReactComponent as LdoIcon } from '../../assets/ldo-logo.svg'
import { ReactComponent as WethIcon } from '../../assets/weth.svg'
import { ReactComponent as UsdcIcon } from 'cryptocurrency-icons/svg/icon/usdc.svg'
import { ReactComponent as UsdtIcon } from 'cryptocurrency-icons/svg/icon/usdt.svg'

import { type ReactElement } from 'react'
import { Box } from '@mui/material'

import  UsdIcon from '../../assets/st-usd.svg'
import  EuroIcon from '../../assets/st-eur.svg'
interface Details {
    name: string
    icon: ReactElement
    smallIcon: ReactElement
}

interface IconProps {
    IconComponent: React.ElementType
    name: string
}
const boxWidth ={ xs: '24px', sm: '28px', md: '32px' }
const boxHeight = { xs: '24px', sm: '28px', md: '32px' } 
const createResponsiveIcon = ({ IconComponent, name }: IconProps): Details => {
  
    return {
        icon: (
            <Box
                width={boxWidth}
                height={boxHeight}
                display="inline-flex"
                alignItems="center"
                justifyContent="center"
            >
                <IconComponent sx={{ width: '100%', height: '100%' }} />
            </Box>
        ),
        smallIcon: (
            <IconComponent
                style={{
                    transform: 'scale(.8)',
                    height: '32px',
                    width: '32px',
                }}
            />
        ),
        name,
    }
}

export const Currencies: {
    [key in SupportedToken]: Details
} = {
    eth: createResponsiveIcon({ IconComponent: EthIcon, name: TOKENS.eth.symbol }),
    weth: createResponsiveIcon({ IconComponent: WethIcon, name: TOKENS.weth.symbol }),
    usdc: createResponsiveIcon({ IconComponent: UsdcIcon, name: TOKENS.usdc.symbol }),
    usdt: createResponsiveIcon({ IconComponent: UsdtIcon, name: TOKENS.usdt.symbol }),
    link: createResponsiveIcon({ IconComponent: LinkIcon, name: TOKENS.link.symbol }),
    imx: createResponsiveIcon({ IconComponent: ImxIcon, name: TOKENS.imx.symbol }),
    matic: createResponsiveIcon({ IconComponent: MaticIcon, name: TOKENS.matic.symbol }),
    mkr: createResponsiveIcon({ IconComponent: MkrIcon, name: TOKENS.mkr.symbol }),
    uni: createResponsiveIcon({ IconComponent: UniIcon, name: TOKENS.uni.symbol }),
    aave: createResponsiveIcon({ IconComponent: AaveIcon, name: TOKENS.aave.symbol }),
    shib: createResponsiveIcon({ IconComponent: ShibIcon, name: TOKENS.shib.symbol }),
    pepe: createResponsiveIcon({ IconComponent: PepeIcon, name: TOKENS.pepe.symbol }),
    ape: createResponsiveIcon({ IconComponent: ApeIcon, name: TOKENS.ape.symbol }),
    crv: createResponsiveIcon({ IconComponent: CrvIcon, name: TOKENS.crv.symbol }),
    ldo: createResponsiveIcon({ IconComponent: LdoIcon, name: TOKENS.knc.symbol }),
    knc: createResponsiveIcon({ IconComponent: KncIcon, name: TOKENS.knc.symbol }),
    bnb: createResponsiveIcon({ IconComponent: BnbIcon, name: TOKENS.bnb.symbol }),
    wmatic: createResponsiveIcon({ IconComponent: WavaxIcon, name: TOKENS.wmatic.symbol }),
    wbnb: createResponsiveIcon({ IconComponent: BnbIcon, name: TOKENS.wbnb.symbol }),
    wavax: createResponsiveIcon({ IconComponent: WavaxIcon, name: TOKENS.wavax.symbol }),
    w3c: createResponsiveIcon({ IconComponent: EthIcon, name: TOKENS.w3c.symbol }),
    steur: createResponsiveIcon({ IconComponent: () => <Box component="img" maxWidth="100%" src={EuroIcon}/>, name: TOKENS.steur.symbol }),
    stusd: createResponsiveIcon({ IconComponent: () => <Box component="img" maxWidth="100%" src={UsdIcon}/>, name: TOKENS.stusd.symbol })
}

export const Blockchains: {
    [key in SupportedBlockchain]: Details
} = {
    bnb: createResponsiveIcon({ IconComponent: BnbChainIcon, name: 'BNB' }),
    solana: createResponsiveIcon({ IconComponent: SolanaIcon, name: 'Solana' }),
    ethereum: createResponsiveIcon({
        IconComponent: EthIcon,
        name: 'Ethereum',
    }),
    linea: createResponsiveIcon({ IconComponent: LineaIcon, name: 'Linea' }),
    etherlink: createResponsiveIcon({
        IconComponent: EtherlinkIcon,
        name: 'Etherlink',
    }),
    // mocked not used...
    base: createResponsiveIcon({
        IconComponent: EthIcon,
        name: 'Ethereum',
    }),
}

export const Wallets: {
    [key in SupportedWallet]: {
        icon: ReactElement
        smallIcon: ReactElement
        simpleName: string
        validator: (arg1?: any, arg2?: any) => boolean | Promise<boolean>
    }
} = {
    metamask: {
        icon: <SmallMetamask />,
        smallIcon: <SmallMetamask />,
        simpleName: 'Metamask',
        validator: WALLET_VALIDATORS.metamask,
    },
    phantom: {
        icon: <SmallPhantom />,
        smallIcon: <SmallPhantom />,
        simpleName: 'Phantom',
        validator: WALLET_VALIDATORS.phantom,
    },
    ledger: {
        icon: <Ledger />,
        smallIcon: <SmallLedger />,
        simpleName: 'Ledger',
        validator: WALLET_VALIDATORS.ledger,
    },
}
