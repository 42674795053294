import { SolanaLedger } from './blockchain-providers/ledger-solana-provider'
import addressConfig, { SupportedBlockchain, BLOCKCHAINS, SupportedWebApp, infuraConfig } from '@baanx/blockchain-config'
import {
    type AppMode,
    type BlockchainConfig,
    type BlockchainService,
    type Config,
    SUPPORTED_BLOCKCHAINS_BY_APP_MODE,
} from '../../types'
import { blockchainProvider } from './blockchain-providers/evm-provider'
import { SolanaProvider } from './blockchain-providers/solana-provider'
import { ledgerEthProvider } from './blockchain-providers/ledger-evm-provider'
import { BrowserProvider } from 'ethers'

export interface WalletProvider {
    connectWallet: (
        network: SupportedBlockchain,
        walletProvider: any,
        index?: number,
        sdk?: any,
        cfg?: Config, 
        isAccountSwitch?: boolean
    ) => Promise<void>
    getNetwork: () => SupportedBlockchain
    getAccountId: () => string
    isConnected: () => boolean
    getProvider: () => any
    hasWalletProviderInstalled: () => boolean
    setOnChangeHandler: (refreshFn: () => Promise<void>) => void
    switchAccount?: () => Promise<string[]> 
    getAddresses?: (init: number, end: number) => Promise<string[]>
}

export const getBlockchainsConfig = (cfg: Config) => {
    const config = addressConfig[cfg.blockchainConfigKey ?? '']
    if (!config) {
        throw new Error(
            'FATAL No blockchain configuration available for ' +
            cfg.blockchainConfigKey
        )
    }
    return config
}

export function getChainIdByName(chainName: SupportedBlockchain, cfg: Config) {
    const config = addressConfig[cfg.blockchainConfigKey ?? '']
    if (!config) {
        throw new Error(
            'FATAL No blockchain configuration available for ' +
            cfg.blockchainConfigKey
        )
    }
    if (!config[chainName])
        throw new Error(`FATAL No blockchain configuration for ${chainName}`)

    return config[chainName].chainId
}

export function getUiConfig(blockchainName: string, cfg: Config) {
    const config = BLOCKCHAINS[blockchainName]
    if (!config) {
        throw new Error(
            '<getUiConfig> No blockchain configuration available for ' +
            cfg.blockchainConfigKey
        )
    }
    return config
}

export const getInfuraApiKey = (cfg: Config) => {
    const infuraApiKey = infuraConfig[cfg.blockchainConfigKey ?? '']
    if (!infuraApiKey) {
        throw new Error(
            'FATAL No infuraKey configuration available for ' +
            cfg.blockchainConfigKey
        )
    }
    return infuraApiKey

}

export default function getConfig(blockchainName: string, cfg: Config): BlockchainConfig {
    const config = addressConfig[cfg.blockchainConfigKey ?? '']
    if (!config) {
        throw new Error(
            'FATAL No blockchain configuration available for ' +
            cfg.blockchainConfigKey
        )
    }

    const blockchainConfig = config[blockchainName]
    // TODO remove this cast, reverseEnumMap or use SupportedBlockchain as parameter
    blockchainConfig.blockchain = blockchainName as SupportedBlockchain
    if (!blockchainConfig) {
        throw new Error(
            `FATAL No blockchain configuration available for ${cfg.blockchainConfigKey} for ${blockchainName}`
        )
    }

    return blockchainConfig
}

const cacheEthProvider: Record<string, BlockchainService> = {} as any

export async function getBlockchainService(
    blockchain: SupportedBlockchain,
    provider: any,
    isLedger = false,
    cache = true,
    ledgerIndex = 0,
    cfg: Config
): Promise<BlockchainService> {
    const config = getConfig(blockchain, cfg)
    const isSolana = blockchain.startsWith('solana')
    const cacheKey = blockchain + isLedger
    try {
        let solanaProvider

        if (isSolana && isLedger) {
            solanaProvider = new SolanaLedger(provider, config, ledgerIndex)
        } else if (isSolana && !isLedger) {
            solanaProvider = new SolanaProvider(provider, config)
        }

        if (solanaProvider) {
            await solanaProvider.init()
            return solanaProvider
        }

        if (!cache) {
            return isLedger
                ? await ledgerEthProvider(provider, config, ledgerIndex)
                : await blockchainProvider(provider, config)
        }
        if (!cacheEthProvider[cacheKey]) {
            cacheEthProvider[cacheKey] = isLedger
                ? await ledgerEthProvider(provider, config)
                : await blockchainProvider(provider, config)
        }

        return cacheEthProvider[cacheKey]
    } catch (error) {
        console.error(
            `FATAL can't initialize erc20 service for ${blockchain} with config ${JSON.stringify(
                config
            )}`
        )
        console.error(error)
        console.error((error as Error).stack)
        throw error
    }
}

/**
 * The getConfig assumes that the blockchainName is always equal to some property on blockchain.json. But on this case, we don't have the blockchain name
 * because we do this before connecting. We only know that the user wants to connect to BNB.
 */
const getCustomConfig = (blockchainName: string, cfg: Config) => {
    const config = addressConfig[cfg.blockchainConfigKey ?? '']

    for (const keys of Object.keys(config)) {
        if (keys.includes(blockchainName)) {
            return config[keys]
        }
    }
    throw new Error(
        'No blockchain configuration available for ' + blockchainName
    )
}

const addNetwork = async (blockchainName: string, provider, cfg: Config) => {
    const chainConfig = getCustomConfig(blockchainName, cfg)
    const networkToAdd = {
        chainId: chainConfig.chainId,
        chainName: chainConfig.chainName,
        rpcUrls: [chainConfig.publicRpcUrl],
        blockExplorerUrls: [chainConfig.blockExplorerUrl],
        nativeCurrency: {
            symbol: chainConfig.currencySymbol,
            name: chainConfig.currencyName,
            decimals: chainConfig.currencyDecimals,
        },
    }
    try {
        await provider.request({
            method: 'wallet_addEthereumChain',
            params: [networkToAdd],
        })
    }catch(error) {
        console.error("error while adding: ", error)
        throw error;
    }

}

let isChanging = false

export const switchOrAddNetwork = async (
    blockchainName: string,
    provider: any,
    cfg: Config
) => {
    if (isChanging) return
    isChanging = true
    try {
        const chainId = getChainIdByName(blockchainName as SupportedBlockchain, cfg)
        const browserChainId = (
            await new BrowserProvider(provider).getNetwork()
        ).chainId
        if (BigInt(chainId) === BigInt(browserChainId)) return
        await provider
            .request({
                method: 'wallet_switchEthereumChain',
                params: [{chainId}],
            })
            .catch(async (error) => {
                if ([4902, -26002, -32603].includes(error.code)) {
                    await addNetwork(blockchainName, provider, cfg)
                    return
                }
                throw error
            })
    } finally {
        isChanging = false
    }
}

// Get blockchains visible on the UI
export const getBlockchains = (
    appMode: AppMode,
    webApp: SupportedWebApp = SupportedWebApp.FOX,
    enabledBlockchains?: SupportedBlockchain[]
) => {
    const blockchains = SUPPORTED_BLOCKCHAINS_BY_APP_MODE[appMode]

    if (webApp === SupportedWebApp.KINEKT) {
        return blockchains.filter(
          (blockchain) =>
            (enabledBlockchains?.includes(blockchain) ?? true)
        );
    }

    return blockchains
}
