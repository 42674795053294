import {
    Button,
    Grid,
    IconButton,
    Dialog,
    Typography,
    ScopedCssBaseline,
} from '@mui/material'
import CloseSVG from '@mui/icons-material/Close'
import InfoIcon from '@mui/icons-material/Info'

interface ConfirmModalProps {
    open: boolean
    title: string
    description: string
    onConfirm: () => void
    onCancel: () => void
    confirmLabel?: string
    cancelLabel?: string
}
const ConfirmModal = ({
    open,
    title,
    description,
    onConfirm,
    onCancel,
    confirmLabel = 'Confirm',
    cancelLabel = 'Cancel',
}: ConfirmModalProps) => {
    return (
        <ScopedCssBaseline>
            <Dialog open={open} onClose={onCancel} disableAutoFocus={true}>
                <Grid container spacing={2} p={4} textAlign={'center'}>
                    <Grid item container xs={12}>
                        <Grid item xs={11} />
                        <Grid item xs={1}>
                            <IconButton color="inherit" onClick={onCancel}>
                                <CloseSVG />
                            </IconButton>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <InfoIcon sx={{ fontSize: '6rem', color: '#0960A5' }} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h2">{title}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="body1">{description}</Typography>
                    </Grid>
                    <Grid mt={2} container columnSpacing={1}>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                variant="contained"
                                disableElevation
                                onClick={onConfirm}
                            >
                                {confirmLabel}
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                variant="contained"
                                disableElevation
                                onClick={onCancel}
                            >
                                {cancelLabel}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
        </ScopedCssBaseline>
    )
}

export default ConfirmModal
