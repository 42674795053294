import { Box, CircularProgress, Grid } from '@mui/material'

const Loading = () => {
  return (
    <Grid
      item
      container
      xs={12}
      sx={{
        zIndex: 1
      }}
      height={'100%'}
    >
<Grid
  container
  direction="column"
  justifyContent="center"
  alignItems="center"
>        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size="5rem" />
        </Box>
      </Grid>
    </Grid>
  )
}

export default Loading
