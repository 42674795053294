import { ethers } from 'ethers'
import TokenABI from '@baanx/abis/ERC20.json'
import {
    type BlockchainConfig,
    type BlockchainService,
    type Transaction,
    SupportedToken,
} from '../../../types'
import { getParsedAmount } from '../../../utils'
import type Eth from '@ledgerhq/hw-app-eth'
import { ledgerService } from '@ledgerhq/hw-app-eth'
import { getAccountPath } from '../wallet-providers/ledger-wallet-provider'

export const ledgerEthProvider = async (
    providerParam: any,
    cfg: BlockchainConfig,
    index = 0
): Promise<BlockchainService> => {
    const accountPath = getAccountPath('eth', index)
    const provider: Eth = providerParam as Eth
    const connection = new ethers.JsonRpcProvider(
        cfg.jsonRpcUrl,
        +(cfg.chainId || 0).toString(10)
    )

    const erc20Contracts = Object.keys(cfg.token).reduce(
        (acc, cur) => ({
            ...acc,
            [`${cur}`]: new ethers.Contract(
                cfg.token[cur].address,
                TokenABI.abi,
                connection
            ),
        }),
        {}
    )
    const tokenDecimals = {}

    const tokens = Object.keys(cfg.token) as SupportedToken[]
    for (const token of tokens) {
        const decimals = await erc20Contracts[token].decimals()
        tokenDecimals[token] = decimals
    }

    const waitForTransaction = async (_hash: string): Promise<void> => {}

    const getBalance = async (accountId: string): Promise<string> => {
        if (!provider) throw Error('Not connected')

        const balance = await connection.getBalance(accountId)
        return ethers.formatUnits(balance)
    }

    const getAllowance = async (
        accountId: string,
        tokenType: SupportedToken
    ): Promise<string> => {
        const allowance = await erc20Contracts[tokenType].allowance(
            accountId,
            cfg.contractAddress
        )

        const isInfinite = ethers.MaxUint256.toString() === allowance.toString()

        const decimals = tokenDecimals[tokenType]
        const decimalsToUse = isInfinite ? 0 : decimals

        return ethers.formatUnits(allowance, decimalsToUse)
    }

    const approve = async (
        amount: string,
        tokenType: SupportedToken
    ): Promise<Transaction> => {
        const decimals = tokenDecimals[tokenType]
        const parsedAmount = getParsedAmount(amount, decimals)

        const approveData = await erc20Contracts[
            tokenType
        ].approve.populateTransaction(cfg.contractAddress, parsedAmount)

        const { address } = await provider.getAddress(accountPath)
        const nonce = await connection.getTransactionCount(address, 'latest')

        const gasPrice = (await connection.getFeeData()).gasPrice

        // Building transaction with the information gathered
        const transaction = {
            to: cfg.token[tokenType]?.address,
            gasPrice:
                '0x' +
                parseInt(ethers.toBeHex(gasPrice as bigint)).toString(16),
            gasLimit: ethers.toBeHex(100000),
            nonce,
            chainId: +(cfg.chainId || 1),
            data: approveData.data,
            value: '0x00',
        }

        const signedTx = await signTransaction(transaction)

        const { hash } = await connection.broadcastTransaction(signedTx)
        return { hash }
    }

    const balanceOf = async (
        accountId: string,
        tokenType: SupportedToken
    ): Promise<string> => {
        const balance = await erc20Contracts[tokenType].balanceOf(accountId)
        const decimals = tokenDecimals[tokenType]
        return ethers.formatUnits(balance, decimals)
    }

    const signTransaction = async (transaction: any): Promise<string> => {
        const unsignedTx =
            ethers.Transaction.from(transaction).unsignedSerialized.substring(2)

        const resolution = await ledgerService.resolveTransaction(
            unsignedTx,
            provider.loadConfig,
            {
                erc20: true,
                nft: true,
                externalPlugins: true,
            }
        )
        const signature = await provider.signTransaction(
            accountPath,
            unsignedTx,
            resolution
        )

        // Parse the signature
        signature.r = '0x' + signature.r
        signature.s = '0x' + signature.s
        signature.v = '0x' + signature.v

        transaction.signature = signature
        // signature.from = address

        // Serialize the same transaction as before, but adding the signature on it
        return ethers.Transaction.from(transaction).serialized
    }

    const paymentErc20 = async (
        amount: string,
        tokenType: SupportedToken
    ): Promise<Transaction> => {
        const transferData = await erc20Contracts[
            tokenType
        ].transfer.populateTransaction(
            cfg.paymentAddress,
            ethers.parseUnits(amount, tokenDecimals[tokenType])
        )

        const { address } = await provider.getAddress(accountPath)
        const nonce = await connection.getTransactionCount(address, 'latest')

        const gasPrice = (await connection.getFeeData()).gasPrice
        // Building transaction with the information gathered
        const transaction = {
            to: cfg.token[tokenType]?.address,
            gasPrice:
                '0x' +
                parseInt(ethers.toBeHex(gasPrice as bigint)).toString(16),
            gasLimit: ethers.toBeHex(100000),
            nonce,
            chainId: +(cfg.chainId || 1),
            data: transferData.data,
        }

        const signedTx = await signTransaction(transaction)
        const { hash } = await connection.broadcastTransaction(signedTx)
        return { hash }
    }
    const payment = async (
        amount: string,
        unit: SupportedToken
    ): Promise<Transaction> => {
        if (unit !== SupportedToken.ETH) {
            return await paymentErc20(amount, unit)
        }

        const { address } = await provider.getAddress(accountPath)

        const nonce = await connection.getTransactionCount(address, 'latest')

        const gasPrice = ethers.toBeHex(
            (await connection.getFeeData()).gasPrice as bigint
        )

        const tx = {
            to: cfg.paymentAddress,
            gasPrice: '0x' + parseInt(gasPrice).toString(16),
            gasLimit: ethers.toBeHex(100000),
            nonce,
            data: '0x00',
            chainId: +(cfg.chainId || 1),
            value: ethers.toBeHex(ethers.parseEther(amount)),
        }

        const signedTx = await signTransaction(tx)
        const { hash } = await connection.broadcastTransaction(signedTx)
        return { hash }
    }

    const decimals = (tokenType: SupportedToken): number =>
        tokenDecimals[tokenType]

    const signMessage = async (message: string): Promise<string> => {
        const signature = await provider.signPersonalMessage(
            accountPath,
            Buffer.from(message).toString('hex')
        )
        return `0x${signature.r}${signature.s}${signature.v.toString(16)}`
    }

    const signTypedData = async (domain, types, message): Promise<ethers.SignatureLike> => {
        try {
            const {r, s, v} = await provider
                .signEIP712Message(accountPath, {
                    domain,
                    types,
                    primaryType: 'Swap',
                    message,
                })
            return { r: `0x${r}`, s: `0x${s}`, v }
        } catch (error: any) {
            // Handle incompatibility with Ledger Nano S. Fallback to signEIP712HashedMessage. See https://www.npmjs.com/package/@ledgerhq/hw-app-eth#signeip712message
            if (error.statusCode === 27264) {
                // Generate the domain separator
                const domainSeparatorHex = ethers.keccak256(
                    ethers.AbiCoder.defaultAbiCoder().encode(
                        ['bytes32', 'bytes32', 'bytes32', 'uint256', 'address'],
                        [
                            ethers.id(
                                'EIP712Domain(string name,string version,uint256 chainId,address verifyingContract)'
                            ),
                            ethers.id(domain.name),
                            ethers.id(domain.version),
                            domain.chainId,
                            domain.verifyingContract,
                        ]
                    )
                )
                const typedDataEncoder = new ethers.TypedDataEncoder(types)
                const hashStructMessageHex = typedDataEncoder.hash(message)
                const { r, s, v } = await provider.signEIP712HashedMessage(
                    accountPath,
                    domainSeparatorHex,
                    hashStructMessageHex
                )
                return { r: `0x${r}`, s: `0x${s}`, v }
            }
            throw error
        }
    }

    return {
        waitForTransaction,
        getBalance,
        getAllowance,
        approve,
        balanceOf,
        decimals,
        payment,
        signMessage,
        signTypedData,
    }
}
