import zIndex from "@mui/material/styles/zIndex";

// TEMP
console.log(zIndex);
const styles = {
    modal: {
      position: 'fixed',
      left: '0',
      top: '0',
      width: "100vw",
      height: "100vh",
      bgcolor: 'background.default',
    },
  };
  
  export default styles;
  