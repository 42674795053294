import Chip from '@mui/material/Chip';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { styled } from '@mui/material';

interface SpendingLimitChipProps {
    text: string
}
/**
 * From https://github.com/mui/material-ui/issues/15185#issuecomment-1556335683
 * If we don't want the text to break line when overflowing the chip, we can delete this. 
 * Note that the text will be hidden if it's too long without it. 
 **/
const CustomChip = styled(Chip)(({theme}) => ({
  padding: theme.spacing(1), height: '100%', display: 'flex', flexDirection: 'row',
  '& .MuiChip-label': { overflowWrap: 'break-word', whiteSpace: 'normal', textOverflow: 'clip' },
  borderRadius: "0.5rem",
  fontSize: "0.75rem",
}))
const SpendingLimitChip = ({ text }: SpendingLimitChipProps) => {
  return (
    <CustomChip
      icon={<CreditCardIcon />}
      label={text}
      size="small"
    />
  );
};

export default SpendingLimitChip;