import { Box, Dialog, Grid, IconButton, Typography } from '@mui/material'
import { useModal } from '../../contexts/ModalContext'
import CloseSVG from '@mui/icons-material/Close'
import Loading from '../loading/Loading'

const ProcessingModal = () => {
  const {
    showProcessing: open,
    toggleProcessing,
    processingDesc,
    isProcessingCancelable
  } = useModal()

  const handleClose = () => {
    if (!isProcessingCancelable) return
    toggleProcessing(false)
  }
  return (
    <Dialog open={open} onClose={handleClose} disableAutoFocus={true}>
      <Grid container spacing={2} p={4}>
        <Grid item container xs={12}>
          <Grid item xs={11} />
          <Grid item xs={1}>
            {isProcessingCancelable && (
              <IconButton color="inherit" onClick={handleClose}>
                <CloseSVG />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Loading />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" variant="h4" fontWeight={400}>Processing...</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography align="center" variant="body1">{processingDesc}</Typography>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default ProcessingModal
