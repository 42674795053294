import {
    SupportedWallet,
    SupportedToken,
    SUPPORTED_SWAP_BLOCKCHAINS,
    SupportedBlockchain,
} from '@baanx/blockchain-config'
import { type ethers } from 'ethers'

// Type for getUserAddresses endpoint response
export interface ConnectAddress {
    id: string
    blockchain: SupportedBlockchain
    address: string
    currency: SupportedToken
    priority: number
}

// Naming convention:
// /some-url -> SOME_URL
// /sign/verify -> SIGN_VERIFY
export enum NavigateLocations {
    CONNECT_NETWORK = '/network',
    CONNECT_USER_WALLET = '/connect-user-wallet',
    SELECT_LEDGER_ADDRESS = '/select-ledger-address',
    DELEGATE_SWAP = '/delegate-swap',
    CONNECT_WALLET = '/connect-wallet',
    SIGN = '/sign',
    FOX_APP_MOCK = '/fox-app-mock',
    FOX_DELEGATE = '/fox-delegate',
    SDK_TEST = '/mm-sdk-test',
}

export interface Transaction {
    hash: string
    blockNumber?: number
    status?: TransactionStatus
    wait?: () => Promise<any>
}

enum TransactionStatus {
    OK = 'OK',
    KO = 'KO',
}

export interface ApprovalDTO {
    address: string
    blockchain: SupportedBlockchain
    currency: string
    amount: string
    transaction: { hash: string }
}

export interface BlockchainConfig {
    blockchain: SupportedBlockchain
    beneficiaryAddress: string
    paymentAddress: string
    contractAddress: string
    operatorAddress: string
    operatorKey: string
    jsonRpcUrl: string
    chainId: string
    token: {
        [key in SupportedToken]: {
            address: string
        }
    }
}
export interface BlockchainService {
    waitForTransaction: (hash: string) => Promise<void>
    getBalance: (address: string) => Promise<string>
    getAllowance: (
        address: string,
        tokenType: SupportedToken
    ) => Promise<string>
    approve: (
        amount: string,
        tokenType: SupportedToken
    ) => Promise<Partial<Transaction>>
    balanceOf: (address: string, tokenType: SupportedToken) => Promise<string>
    validateCurrency?: (tokenType: SupportedToken) => Promise<boolean>
    decimals: (tokenType: SupportedToken) => number
    payment: (
        amount: string,
        unit: SupportedToken
    ) => Promise<Transaction>
    signMessage: (message: string) => Promise<string>
    signTypedData: (domain, types, message) => Promise<ethers.SignatureLike>
}

export const WALLET_MAP: { [key in SupportedBlockchain]: SupportedWallet[] } = {
    bnb: [SupportedWallet.METAMASK, SupportedWallet.LEDGER],
    solana: [SupportedWallet.PHANTOM, SupportedWallet.LEDGER],
    ethereum: [SupportedWallet.METAMASK, SupportedWallet.LEDGER],
    linea: [SupportedWallet.METAMASK],
    etherlink: [SupportedWallet.METAMASK],
    // TODO: Not used on Fox
    base: [],
}

export const WALLET_MOBILE_COMPATIBILITY: { [key in SupportedWallet]: boolean } = {
    [SupportedWallet.LEDGER]: false,
    [SupportedWallet.METAMASK]: true,
    [SupportedWallet.PHANTOM]: false,
}


export enum AppMode {
    CONNECT_WALLET = 'connect_wallet',
    SWAP = 'swap',
    SIGN = 'sign',
    FOX = 'fox',
}

export const SUPPORTED_BLOCKCHAINS_BY_APP_MODE: {
    [key in AppMode]: SupportedBlockchain[]
} = {
    [AppMode.SWAP]: SUPPORTED_SWAP_BLOCKCHAINS,
    [AppMode.CONNECT_WALLET]: SUPPORTED_SWAP_BLOCKCHAINS,
    [AppMode.SIGN]: SUPPORTED_SWAP_BLOCKCHAINS,
    [AppMode.FOX]: [SupportedBlockchain.ETHEREUM],
}
export const STABLE_COINS = [SupportedToken.USDC, SupportedToken.USDT]
export * from '@baanx/blockchain-config'
