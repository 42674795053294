import { Button, Dialog, Grid, IconButton, Typography } from '@mui/material'
import { ReactComponent as Success } from '../../assets/success.svg'
import InfoIcon from '@mui/icons-material/Info'
import ErrorIcon from '@mui/icons-material/Error'
import { ResultModalType, useModal } from '../../contexts/ModalContext'
import CloseSVG from '@mui/icons-material/Close'

const ResultModal = () => {
    const {
        showResult: open,
        errorTitle: title,
        errorDesc: description,
        resultType,
        errorComponent,
        isErrorRecoverable,
        hide
    } = useModal()
    const isError = resultType === ResultModalType.ERROR
    const isInfo = resultType === ResultModalType.INFO
    const isSuccess = !isError && !isInfo
    const handleClose = () => {
        if (!isErrorRecoverable) {
            (window as any).top.history.back();
            return
        }
        hide()
    }
    const ResultSymbol = (isSuccess && <Success />) ||
        (isInfo && (
            <InfoIcon sx={{ fontSize: '6rem', color: '#0960A5' }} />
        )) || (
            <ErrorIcon
                sx={{ color: '#BD0022', width: '5rem', height: '5rem' }}
            />
        )

    return (
        <Dialog
            open={open}
            onClose={() => {
                isErrorRecoverable && handleClose()
            }}
            disableAutoFocus={true}
        >
            <Grid container spacing={2} textAlign={'center'} p={4}>
                {isErrorRecoverable && (
                    <Grid item container xs={12}>
                        <Grid item xs={11} />
                        <Grid item xs={1}>
                            <IconButton color="inherit" onClick={handleClose}>
                                <CloseSVG />
                            </IconButton>
                        </Grid>
                    </Grid>
                )}
                <Grid item xs={12}>
                    {ResultSymbol}
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4" fontWeight={400}>{title}</Typography>
                </Grid>

                <Grid item xs={12}>
                    {!!errorComponent && errorComponent}
                    {!errorComponent && (
                        <Typography variant="body1">
                            {description.slice(0, 128)}
                        </Typography>
                    )}
                </Grid>
                    <Grid
                        item
                        xs={12}
                        marginLeft={{xs: 0, md: 12}}
                        marginRight={{xs: 0, md: 12}}
                        marginBottom={2}
                    >
                        <Button
                            disableElevation
                            variant="contained"
                            fullWidth
                            onClick={handleClose}
                        >
                            {isErrorRecoverable ? 'Close' : 'Go back'}
                        </Button>
                    </Grid>
            </Grid>
        </Dialog>
    )
}

export default ResultModal
