import { useEffect, useState } from 'react'
import { ReactComponent as MetamaskLogo } from '../../../assets/SVG_MetaMask_Horizontal_Color.svg'
import { ReactComponent as MetamaskLogoWhite } from '../../../assets/SVG_MetaMask_Horizontal_White.svg'
import styles from './misc/styles'
import { Box, Button, Grid, IconButton, Tab, Tabs, useTheme } from '@mui/material'
import { updateQrCode } from './misc/utils'
import OnBoard from '@metamask/onboarding'
import CloseSVG from "@mui/icons-material/Close";

import usePostMessage from '../../../hooks/usePostMessage'
import { PostOperationType } from '../../../error'

export interface SelectModalProps {
    onClose: () => void
    link: string
    sdkVersion?: string
    connectWithExtension: () => void
}


export const CustomMetaMaskModal = (props: SelectModalProps) => {
    const theme = useTheme()
    const [tab, setTab] = useState('desktop')
    useEffect(() => {
        if (tab === 'mobile') updateQrCode(props.link)
    }, [props.link, tab])
    const isInstalled = !!((window as any).extension)
    const installMetaMask = () => {
        const onboardingExtension = new OnBoard();
        onboardingExtension.startOnboarding();
    }

    const { postSuccessMessage: postCloseMessage } = usePostMessage(
        PostOperationType.CLOSE
    )

    const onCloseHandler = () => {
        postCloseMessage()
    }

    return (
        <Grid
            sx={{ ...styles.modal }}
            textAlign={'center'}
            container
            alignContent={'start'}
            rowSpacing={4}
            p={'1.5rem'}
        >
            <Grid item xs={12} textAlign="end">
                <IconButton color="inherit" onClick={onCloseHandler}>
                    <CloseSVG />
                </IconButton>
            </Grid>
            <Grid container justifyContent="center">
                <Grid item xs={6} textAlign="center">
                    {theme.palette.mode === 'dark' ? (
                        <MetamaskLogoWhite />
                    ) : (
                        <MetamaskLogo />
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12} textAlign="center">
                <Tabs
                    centered
                    variant="fullWidth"
                    value={tab}
                    onChange={(_event, newValue) => {
                        setTab(newValue as string)
                    }}
                >
                    <Tab value="desktop" label="Desktop" />
                    <Tab value="mobile" label="Mobile" />
                </Tabs>
            </Grid>
            <Grid item xs={12} paddingLeft={2} paddingRight={2}>
                {(tab === 'desktop' && (
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={
                            isInstalled
                                ? props.connectWithExtension
                                : installMetaMask
                        }
                    >
                        {isInstalled ? 'Connect' : 'Install MetaMask'}
                    </Button>
                )) || <Box id="sdk-qrcode-container"></Box>}
            </Grid>
        </Grid>
    )
}
