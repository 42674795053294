import { Button, Grid, Paper, Typography } from '@mui/material'
import { type ReactElement, useEffect, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Blockchains, Currencies } from '../../components/blockchain/Utils'
import Loading from '../../components/loading/Loading'
import ConfirmModal from '../../components/modals/ConfirmModal'
import { ResultModalType, useModal } from '../../contexts/ModalContext'
import {
    ErrorMessage,
    PostOperationType,
    PostErrorMessage,
    getErrorMessage,
    handleError,
} from '../../error'
import { useDeleteWalletToken } from '@baanx/common/network/api/token'
import {
    NavigateLocations,
    SupportedWallet,
} from '../../types'
import { formatBalance } from '../../utils'
import {
    ANRK_SWAP_CURRENCY_MAP,
    type SupportedToken,
    TOKENS,
    type SupportedBlockchain,
    SWAP_SIGNATURE_DOMAIN_MAP,
    SWAP_SIGNATURE_VERSION,
    SWAP_SIGNATURE_TYPES,
} from '@baanx/blockchain-config'
import usePostMessage from '../../hooks/usePostMessage'
import getConfig, { getUiConfig } from '@baanx/common/network/blockchain/config'
import { ethers } from 'ethers'
import { useSDK } from '@metamask/sdk-react'
import { isMobile } from 'react-device-detect'
import MetaMaskButton from '../../components/metamask/MetaMaskButton'
import config from '../../config'
import { type SwapData } from '@baanx/domain'
import { type BlockchainHook } from '../../hooks/useBlockchain'

let ledgerHandled = false
let strictInit = false
interface SignHomeProps {
    selectedCurrency: SupportedToken
    selectedWallet: SupportedWallet
    selectedNetwork: SupportedBlockchain
    address: string
    swapData: SwapData
    blockchain: BlockchainHook
    skipConfirmation: boolean
}
const SignHome = ({
    selectedCurrency,
    selectedWallet,
    selectedNetwork,
    blockchain,
    address,
    swapData,
    skipConfirmation
}: SignHomeProps): ReactElement => {
    const { sdk, ready, connected, provider } = useSDK()
    const [control, setControl] = useState(false)

    const {
        refreshConnection,
        signTypedData,
        getAccount,
        getDecimals,
        refreshConnectionRpc,
    } = blockchain
    const { toggleWallet, toggleResult, toggleProcessing } = useModal()
    const [isLoading, setIsLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [modalDescription, setModalDescription] = useState('')
    const [walletIsVerified, setWalletIsVerified] = useState(false)
    const [requestedSignature, setRequestedSignature] = useState(false)

    const { mutateAsync: deleteWalletToken } = useDeleteWalletToken(config)
    // const { refetch: getWalletToken } = useWalletToken()
    const navigate = useNavigate()

    const { postErrorMessage, postSuccessMessage } = usePostMessage(
        PostOperationType.SIGN_TRANSACTION
    )
    const { postSuccessMessage: postAbortMessage } = usePostMessage(
        PostOperationType.ABORT
    )
    const onConfirmModalHandler = async (): Promise<void> => {
        try {
            await deleteWalletToken({})
        } catch (error) {
            handleError(error)
        }
        navigate(NavigateLocations.CONNECT_USER_WALLET, {
            state: { walletChange: true },
        })
    }

    useEffect(() => {
        if (
            selectedCurrency == null ||
            selectedNetwork == null ||
            selectedWallet == null ||
            !ready ||
            !provider ||
            strictInit ||
            isMobile
        ) {
            return
        }
        const isLedger = selectedWallet === SupportedWallet.LEDGER
        void (async () => {
            strictInit = true
            try {
                if (selectedWallet === SupportedWallet.METAMASK && !connected) {
                    await blockchain.connectMetamask(selectedNetwork)
                }
                setIsLoading(true)

                await refreshConnection(
                    selectedNetwork,
                    selectedCurrency,
                    selectedWallet,
                    selectedWallet === SupportedWallet.LEDGER
                )
                const accountId = await getAccount(
                    selectedNetwork,
                    selectedWallet,
                    isLedger
                )

                if (accountId?.toLowerCase() !== address.toLowerCase()) {
                    setOpenModal(true)
                    setModalDescription(
                        `Please reconnect with the previously selected wallet address ${address}. Would you like to disconnect now?`
                    )
                    return
                }

                setWalletIsVerified(true)
                setIsLoading(false)
            } catch (error) {
                const errorMessage = getErrorMessage(error)

                /**  Workaround: When users are redirected to this page from an external site,
                 **  the USB connection is not initialized on the first render.
                 **/
                if (
                    !ledgerHandled &&
                    errorMessage === ErrorMessage.LEDGER_NOT_CONNECTED
                ) {
                    ledgerHandled = true
                    return
                }
                setIsLoading(false)
                postErrorMessage(
                    PostErrorMessage.WALLET_CONNECTION_ERROR,
                    error
                )
            }
        })()
    }, [
        getAccount,
        refreshConnection,
        postErrorMessage,
        selectedCurrency,
        selectedNetwork,
        selectedWallet,
        address,
        ready,
        provider,
        sdk,
        connected,
        blockchain,
    ])

    const onConnectHandler = useCallback(async (): Promise<void> => {
        if (selectedCurrency == null) return
        if (
            !Object.keys(ANRK_SWAP_CURRENCY_MAP).includes(
                selectedNetwork as SupportedBlockchain
            )
        )
            return

        toggleWallet(true, '')

        await refreshConnectionRpc(selectedNetwork as SupportedBlockchain)
        const configMapFrom = getConfig(swapData.from.blockchain, config)
        const configMapTo = getConfig(swapData.to.blockchain, config)
        const fromDecimals = getDecimals(swapData.from.token)
        const message = {
            id: swapData.id,
            from: {
                address: ethers.getAddress(swapData.from.address),
                amount: Number(swapData.from.amount).toFixed(4), // need to use a consistent (not localized) way of generating this string
                rawAmount: ethers.parseUnits(
                    swapData.from.amount,
                    fromDecimals
                ),
                token: TOKENS[swapData.from.token].symbol,
                tokenAddress: configMapFrom.token[swapData.from.token].address,
                blockchain: getUiConfig(swapData.from.blockchain, config).name,
                chainId: configMapFrom.chainId,
            },
            to: {
                address: ethers.getAddress(swapData.to.address),
                token: TOKENS[swapData.to.token].symbol,
                tokenAddress: configMapTo.token[swapData.to.token].address,
                blockchain: getUiConfig(swapData.to.blockchain, config).name,
                chainId: configMapTo.chainId,
            },
            deadline: swapData.deadline,
            timestamp: swapData.timestamp,
        }
        const typedData = {
            domain: {
                name: SWAP_SIGNATURE_DOMAIN_MAP[swapData.from.blockchain],
                version: SWAP_SIGNATURE_VERSION,
                chainId: BigInt(configMapFrom.chainId),
                verifyingContract: configMapFrom.contractAddress,
            },
            types: SWAP_SIGNATURE_TYPES,
            message,
        }

        let signature: ethers.SignatureLike
        try {
            signature = await signTypedData(
                typedData.domain,
                typedData.types,
                typedData.message
            )
            toggleProcessing(true)
        } catch (error) {
            toggleResult(
                true,
                'Error',
                getErrorMessage(error),
                ResultModalType.ERROR
            )
            postErrorMessage(PostErrorMessage.SIGNATURE_ERROR, error)
            return
        }

        postSuccessMessage({
            signature,
        })
        toggleProcessing(false)
    }, [
        getDecimals,
        postErrorMessage,
        postSuccessMessage,
        refreshConnectionRpc,
        selectedCurrency,
        selectedNetwork,
        signTypedData,
        swapData.deadline,
        swapData.from.address,
        swapData.from.amount,
        swapData.from.blockchain,
        swapData.from.token,
        swapData.id,
        swapData.timestamp,
        swapData.to.address,
        swapData.to.blockchain,
        swapData.to.token,
        toggleProcessing,
        toggleResult,
        toggleWallet,
    ])

    const onCancelHandler = (): void => {
        postAbortMessage()
    }

    useEffect(() => {
        if (
            !isMobile &&
            skipConfirmation &&
            walletIsVerified &&
            !requestedSignature
        ) {
            void (async () => {
                try {
                    setRequestedSignature(true)
                    await onConnectHandler()
                } catch (err) {
                    console.error('Error:', err)
                }
            })()
        }
    }, [
        onConnectHandler,
        requestedSignature,
        skipConfirmation,
        setRequestedSignature,
        walletIsVerified,
    ])

    const connectMetamaskHandler = async () => {
        try {
            await blockchain.connectMetamask(selectedNetwork)
            isMobile &&
                (await refreshConnection(
                    selectedNetwork as SupportedBlockchain,
                    selectedCurrency as SupportedToken,
                    selectedWallet as SupportedWallet,
                    selectedWallet === SupportedWallet.LEDGER
                ))

            setControl(true)
        } catch (error) {
            postErrorMessage(PostErrorMessage.WALLET_CONNECTION_ERROR, error)
            throw error
        }
    }
    return (
        <>
            <ConfirmModal
                open={openModal}
                title="Info"
                cancelLabel="Back"
                description={modalDescription}
                onConfirm={onConfirmModalHandler}
                onCancel={onCancelHandler}
            ></ConfirmModal>
            <Grid container p={4}>
                <Grid item xs={12} textAlign={'center'}>
                    <Typography variant="h4">
                        Confirm and Sign Swap Message
                    </Typography>
                </Grid>

                <Grid mt={3} container spacing={1} item xs={12}>
                    <Grid item xs={12} textAlign={'center'}>
                        {selectedNetwork != null &&
                            Blockchains[selectedNetwork].icon}
                    </Grid>
                    <Grid item xs={12} textAlign={'center'}>
                        <Typography variant="subtitle1">
                            {selectedNetwork != null &&
                                Blockchains[selectedNetwork].name}{' '}
                        </Typography>
                    </Grid>
                </Grid>

                {isLoading && <Loading />}
                {!isLoading && (
                    <Grid item xs={12} marginTop={2}>
                        <Paper elevation={0} variant="outlined">
                            <Grid
                                item
                                container
                                p={2}
                                xs={12}
                                alignItems={'center'}
                            >
                                <Grid item xs={6} alignItems={'center'}>
                                    <Grid item xs={12}>
                                        <Typography
                                            align="left"
                                            variant="body1"
                                        >
                                            From
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography align="left" variant="h4">
                                            {formatBalance(
                                                swapData.from.amount
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid
                                    item
                                    xs={6}
                                    container
                                    direction="row"
                                    paddingTop={1}
                                    textAlign={'end'}
                                >
                                    <Grid item xs={12}>
                                        {selectedCurrency != null &&
                                            Currencies[selectedCurrency].icon}
                                    </Grid>
                                    <Grid item xs={12} paddingBottom={1}>
                                        {selectedCurrency != null &&
                                            Currencies[selectedCurrency].name}
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container p={2} alignItems={'center'}>
                                <Grid item xs={6} alignItems={'center'}>
                                    <Typography align="left" variant="body1">
                                        To
                                    </Typography>
                                </Grid>

                                <Grid
                                    item
                                    xs={6}
                                    container
                                    direction="row"
                                    textAlign={'end'}
                                    paddingTop={1}
                                >
                                    <Grid item xs={12}>
                                        {swapData.to.token != null &&
                                            Currencies[swapData.to.token].icon}
                                    </Grid>
                                    <Grid item xs={12} paddingBottom={1}>
                                        {swapData.to.token != null &&
                                            Currencies[swapData.to.token].name}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>

                        {isMobile &&
                        selectedWallet === SupportedWallet.METAMASK &&
                        !control ? (
                            <>
                                <MetaMaskButton
                                    connect={connectMetamaskHandler}
                                ></MetaMaskButton>
                            </>
                        ) : (
                            <Grid container marginTop={4} columnSpacing={1}>
                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="secondary"
                                        onClick={onCancelHandler}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>

                                <Grid item xs={6}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        onClick={onConnectHandler}
                                    >
                                        Sign
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                )}
            </Grid>
        </>
    )
}

export default SignHome
