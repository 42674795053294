import { useCallback, useState } from 'react'
import getConfig, {
    getBlockchainService,
    getChainIdByName,
    type WalletProvider,
} from '@baanx/common/network/blockchain/config'
import solanaWalletProvider from '@baanx/common/network/blockchain/wallet-providers/solana-wallet-provider'
import EvmWalletProvider from '@baanx/common/network/blockchain/wallet-providers/evm-wallet-provider'
import {
    type BlockchainService,
    type ConnectAddress, SupportedBlockchain,
    type SupportedToken,
    type SupportedWallet,
    type Transaction,
} from '../types'
import ledgerWalletProvider from '@baanx/common/network/blockchain/wallet-providers/ledger-wallet-provider'
import { ethers } from 'ethers'
import { WALLET_PROVIDERS } from '../network/blockchain/wallet-utils'
import { useSDK } from '@metamask/sdk-react'
import { isMobile } from 'react-device-detect'
import cfg from '../config'

export interface BlockchainHook {
    refreshConnectionRpc: (network: SupportedBlockchain) => Promise<void>
    refreshConnection: (
        network: SupportedBlockchain,
        currency: SupportedToken,
        selectedWallet: SupportedWallet,
        isLedger?: boolean,
        isPayment?: boolean
    ) => Promise<string>
    getAccount: (
        network: SupportedBlockchain,
        selectedWallet: SupportedWallet,
        isLedger?: boolean,
        walletChange?: boolean,
        ledgerIndex?: number
    ) => Promise<string>
    isConnected: boolean
    delegateFunds: (
        amount: string | undefined,
        tokenType: SupportedToken
    ) => Promise<Partial<Transaction>>
    getAllowance: (
        address: string,
        tokenType: SupportedToken
    ) => Promise<string>
    tokenBalance: string
    disconnect: () => void
    isLoading: boolean
    setAddresses: (addresses: ConnectAddress[]) => void
    addresses: ConnectAddress[]
    payment: (amount: string, unit: any) => Promise<any>
    balanceOf: (
        address: string,
        tokenType: SupportedToken
    ) => Promise<string> | undefined
    connectedAccount: string | undefined
    getBalance: (address: string) => Promise<string> | undefined
    getLedgerAddresses: (
        isSolana: boolean,
        init: number,
        end: number
    ) => Promise<string[]>
    setLedgerIndex: (index: number) => void
    ledgerIndex: number
    hasSelectLedgerAddress: boolean
    setHasSelectLedgerAddress: (hasSelectLedgerAddress: boolean) => void
    signMessage: (message: string) => Promise<string>
    signTypedData: (domain, types, message) => Promise<ethers.SignatureLike>
    getDecimals: (tokenAddress: SupportedToken) => number
    connectMetamask: (selectedBlockchain: SupportedBlockchain | undefined) => Promise<void>
    switchAccount: () => Promise<void>
    allowance: string
}
let blockchainProvider: BlockchainService
let blockchainProviderRpc: BlockchainService

let walletProvider: WalletProvider
let ledgerEthWalletProvider: WalletProvider
let ledgerSolWalletProvider: WalletProvider

const getWalletProvider = (isSolana = false, isLedger = false) => {
    if (isSolana && !isLedger) {
        return solanaWalletProvider()
    } else if (!isSolana && isLedger) {
        if (!ledgerEthWalletProvider) {
            ledgerEthWalletProvider = ledgerWalletProvider('eth')
        }
        return ledgerEthWalletProvider
    } else if (isSolana && isLedger) {
        if (!ledgerSolWalletProvider) {
            ledgerSolWalletProvider = ledgerWalletProvider('sol')
        }
        return ledgerSolWalletProvider
    }
    // ethereum
    if (!walletProvider) walletProvider = EvmWalletProvider()
    return walletProvider
}

const rpcProvider: Record<SupportedBlockchain, ethers.Provider> = {} as any
const useBlockchain = (): BlockchainHook => {
    const { sdk } = useSDK()

    const getBrowserProvider = useCallback( async (selectedWallet)=> {
        if (selectedWallet === 'metamask') {   
            return sdk?.getProvider()
        }
        return WALLET_PROVIDERS[selectedWallet]();
    },[sdk])
    const [isConnected, setConnected] = useState(false)
    const [tokenBalance, setTokenBalance] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [ledgerIndex, setLedgerIndex] = useState<number>(0)
    const [hasSelectLedgerAddress, setHasSelectLedgerAddress] =
        useState<boolean>(false)
    const [addresses, setAddresses] = useState<ConnectAddress[]>([])
    const [connectedAccount, setConnectedAccount] = useState<string>()
    const [allowance, setAllowance] = useState<string>('0')
    const disconnect = useCallback(() => {
        setConnected(false)
        setTokenBalance('')
        setHasSelectLedgerAddress(false)
        setLedgerIndex(0)
        setAddresses([])
        setConnectedAccount(undefined)
    }, [])

    const connectMetamask = useCallback(
        async (selectedChain?: SupportedBlockchain) => {
            if (!selectedChain) throw Error('Selected chain is undefined')

            if (isMobile) {
              
                const switchChainRPC = {
                    method: 'wallet_switchEthereumChain',
                    params: [
                        {
                            chainId: getChainIdByName(selectedChain, cfg),
                        },
                    ],
                }
                await sdk?.connectWith(switchChainRPC)
                return
            }
            try {
                await sdk?.connect()
            } catch (error: any) {
                if (error?.message?.match(/already processing/i)) {
                    await sdk?.getProvider?.()?.request({
                        method: 'wallet_requestPermissions',
                        params: [{ eth_accounts: {} }],
                      })
                }
            }
        },
        [sdk]
    )
    const refreshConnectionRpc = useCallback(
        async (networkParam: SupportedBlockchain) => {
            try {
                setIsLoading(true)
                const config = getConfig(networkParam, cfg)

                if (!rpcProvider[networkParam]) {
                    // TODO WARNING refactor this HACK
                    if (config.jsonRpcUrl.startsWith('wss')) {
                        rpcProvider[networkParam] =
                            new ethers.WebSocketProvider(config.jsonRpcUrl)
                    } else {
                        rpcProvider[networkParam] = new ethers.JsonRpcProvider(
                            config.jsonRpcUrl
                        )
                    }
                }
                blockchainProviderRpc = await getBlockchainService(
                    networkParam,
                    rpcProvider[networkParam],
                    false,
                    undefined,
                    ledgerIndex,
                    cfg
                )
            } finally {
                setIsLoading(false)
            }
        },
        [ledgerIndex]
    )

    const internalConnect = useCallback(
        async (
            networkParam: SupportedBlockchain,
            selectedWallet: SupportedWallet,
            currentLedgerIndex,
            isLedger = false,
            _walletChange = false
        ) => {
            const isSolana = networkParam === SupportedBlockchain.SOLANA
            const walletProvider = getWalletProvider(isSolana, isLedger)
            const browserProvider = await getBrowserProvider(selectedWallet)
            // Init wallet Provider
            await walletProvider.connectWallet(
                networkParam,
                browserProvider,
                currentLedgerIndex,
                sdk,
                cfg
            )
            walletProvider.setOnChangeHandler(async () => {
               if (!isMobile) {
                    await sdk?.terminate()
                    window.location.reload()
               }
            })

            setConnected(true)
            setConnectedAccount(walletProvider.getAccountId())

            return walletProvider.getAccountId()
        },
        [getBrowserProvider, sdk]
    )
    const refreshConnection = useCallback(
        async (
            networkParam: SupportedBlockchain,
            currencyParam: SupportedToken,
            selectedWallet: SupportedWallet,
            isLedger = false,
            isPayment = false
        ) => {
            try {
                const isSolana = networkParam === SupportedBlockchain.SOLANA
                setIsLoading(true)
                const walletProvider = getWalletProvider(isSolana, isLedger)
                // Init wallet Provider
                const browserProvider = await getBrowserProvider(selectedWallet)
                await walletProvider.connectWallet(
                    networkParam,
                    browserProvider,
                    ledgerIndex,
                    sdk,
                    cfg
                )
                setConnectedAccount(walletProvider.getAccountId())
              
                walletProvider.setOnChangeHandler(async () => {
                    if (!isMobile) {
                        await sdk?.terminate()
                        window.location.reload()
                       }
                })

                blockchainProvider = await getBlockchainService(
                    networkParam,
                    walletProvider.getProvider(),
                    isLedger,
                    false,
                    ledgerIndex,
                    cfg
                )

                if (blockchainProvider.validateCurrency) {
                    const isValid = await blockchainProvider.validateCurrency(
                        currencyParam
                    )
                    if (!isValid) {
                        throw new Error(
                            `Your account does not support ${currencyParam}. Create a ${currencyParam} token account before using Solana.`
                        )
                    }
                }

                if (!isPayment) {
                    const tokenBalance = await blockchainProvider.balanceOf(
                        walletProvider.getAccountId(),
                        currencyParam
                    )
                    tokenBalance && setTokenBalance(tokenBalance)
                    const allowance = await blockchainProvider.getAllowance(walletProvider.getAccountId(), currencyParam)
                    setAllowance(allowance)
                }
                setConnected(walletProvider.isConnected())
                setConnectedAccount(walletProvider.getAccountId())
                return walletProvider.getAccountId()
            } finally {
                setIsLoading(false)
            }
        },
        [getBrowserProvider, ledgerIndex, sdk]
    )
    const delegateFunds = useCallback(
        async (amount: string | undefined, tokenType: SupportedToken) => {
            if (!amount) throw Error('Amount cannot be undefined!')
            const tx = await blockchainProvider
                .approve(amount, tokenType)
                .catch((error) => {
                    console.error('Error while delegating funds: ', error)
                    throw error
                })
            return tx
        },
        []
    )

    const payment = useCallback(async (amount: string, unit: any) => {
        return await blockchainProvider.payment(amount, unit)
    },[])
    const getAllowance = useCallback(
        async (a: string, t: SupportedToken) =>
            await blockchainProviderRpc?.getAllowance(a, t),
        []
    )
    const balanceOf = useCallback(
        async (a: string, t: SupportedToken): Promise<string> =>
            await blockchainProviderRpc?.balanceOf(a, t)?.catch(() => {
                // Handle when the account does not have a solana token account.
                return '0.0'
            }),
        []
    )
    const getBalance = useCallback(
        async (a: string) => await blockchainProviderRpc?.getBalance(a),
        []
    )

    const getDecimals = useCallback(
         (tokenAddress: SupportedToken) => blockchainProviderRpc.decimals(tokenAddress) 
        ,
        []
    )
    const getAccount = useCallback(
        async (
            network: SupportedBlockchain,
            selectedWallet: SupportedWallet,
            isLedger = false,
            walletChange = false,
            currentLedgerIndex = ledgerIndex
        ) => {
            setIsLoading(true)
            return await internalConnect(
                network,
                selectedWallet,
                currentLedgerIndex,
                isLedger,
                walletChange
            ).finally(() => {
                setIsLoading(false)
            })
        },
        [internalConnect, ledgerIndex]
    )

    const getLedgerAddresses = useCallback(async (
        isSolana: boolean,
        init: number,
        end: number
    ) => {
        const wallet = getWalletProvider(isSolana, true)
        return await (wallet.getAddresses?.(init, end) ?? [])
    },[])

    const signMessage = useCallback(
        async (message: string): Promise<string> =>
            await blockchainProvider?.signMessage(message),
        []
    )
    const signTypedData = useCallback(
        async (domain, types, message): Promise<ethers.SignatureLike> => {
            return await blockchainProvider.signTypedData(domain, types, message)
        },
        []
    )

    const switchAccount = async () => {
        const accounts = await getWalletProvider().switchAccount?.()
        accounts && setConnectedAccount(accounts[0])
    }

    return {
        connectMetamask,
        hasSelectLedgerAddress,
        setHasSelectLedgerAddress,
        ledgerIndex,
        setLedgerIndex,
        getLedgerAddresses,
        refreshConnectionRpc,
        refreshConnection,
        getAccount,
        isConnected,
        delegateFunds,
        tokenBalance,
        disconnect,
        isLoading,
        addresses,
        setAddresses,
        payment,
        getAllowance,
        balanceOf,
        getDecimals,
        getBalance,
        connectedAccount,
        signMessage,
        signTypedData,
        switchAccount, 
        allowance
    }
}

export default useBlockchain
