import React, {
    useState,
    createContext,
    useContext,
    type ReactElement,
} from 'react'
import ProcessingModal from '../components/modals/ProcessingModal'
import ResultModal from '../components/modals/ResultModal'
import WalletModal from '../components/modals/WalletModal'

export enum ResultModalType {
    ERROR = 'error',
    INFO = 'info',
    SUCCESS = 'success',
}

const DefaultTheme = ({children}: {children: any}) => <>{children}</>
interface ModalProviderProps {
    children: ReactElement
}

interface ModalContextType {
    showProcessing: boolean
    showWallet: boolean
    showResult: boolean
    toggleProcessing: (state: boolean, desc?: string) => void
    toggleWallet: (
        state: boolean,
        description: string,
        delegation?: boolean
    ) => void
    toggleResult: (
        state: boolean,
        errorTitle?: string,
        errorDescription?: string,
        resultType?: ResultModalType,
        _errorComponent?: ReactElement,
        isCancelable?: boolean,
        _isNoButton?: boolean
    ) => void
    showPersistentModal: (title: string, description: string) => void
    errorTitle: string
    errorDesc: string
    walletDescription: string
    isErrorRecoverable: boolean
    errorComponent: ReactElement | undefined
    processingDesc: string
    resultType: ResultModalType
    isProcessingCancelable: boolean
    isWalletCancelable: boolean
    isDelegation: boolean
    hide: () => void
    setModalTheme: any
}

const ModalContext = createContext<ModalContextType>({
    showProcessing: false,
    showWallet: false,
    showResult: false,
    toggleProcessing: (_state: boolean, _desc?: string) => {},
    toggleWallet: (
        _state: boolean,
        _walletDescription: string,
        _delegation?: boolean
    ) => {},
    toggleResult: (
        _state: boolean,
        _errorTitle?: string,
        _errorDescription?: string,
        _resultType?: ResultModalType,
        _errorComponent?: ReactElement,
        _isErrorCancelable?: boolean,
        _isNoButton?: boolean
    ) => {},
    showPersistentModal: (_title: string, _description: string) => {},
    errorTitle: '',
    errorDesc: '',
    walletDescription: '',
    isErrorRecoverable: true,
    errorComponent: undefined,
    processingDesc: '',
    resultType: ResultModalType.SUCCESS,
    isProcessingCancelable: false,
    isWalletCancelable: false,
    isDelegation: false,
    hide: () => {},
    setModalTheme: ({children}: {children: any}) => <>{children}</>,
})

export function useModal(): ModalContextType {
    return useContext(ModalContext)
}

export default function ModalProvider({
    children,
}: ModalProviderProps): ReactElement {
    const [showProcessing, setShowProcessing] = useState(false)
    const [showWallet, setShowWallet] = useState(false)
    const [showResult, setShowResult] = useState(false)
    const [errorDesc, setErrorDesc] = useState('')
    const [errorComponent, setErrorDescComponent] = useState<
        ReactElement | undefined
    >()
    const [errorTitle, setErrorTitle] = useState('')
    const [isErrorRecoverable, setIsErrorRecoverable] = useState(true)
    const [walletDescription, setWalletDescription] = useState('')
    const [processingDesc, setProcessingDesc] = useState('')
    const [resultType, setResultType] = useState<ResultModalType>(
        ResultModalType.SUCCESS
    )

    const [isProcessingCancelable, setIsProcessingCancelable] = useState(true)
    const [isWalletCancelable, setIsWalletCancelable] = useState(true)
    const [isDelegation, setIsDelegation] = useState(false)
    const [Theme, setModalTheme] = useState(() => DefaultTheme)

    const [isNoButton, setIsNoButton] = useState(false)
    const showPersistentModal = (title: string, description: string): void => {
        toggleResult(
            true,
            title,
            description,
            ResultModalType.ERROR,
            undefined,
            false
        )
    }

    const hide = () => {
        setShowProcessing(false)
        setShowWallet(false)
        setShowResult(false)
    }

    const toggleProcessing = (
        state: boolean,
        processingText = 'Your request is processing. It should be confirmed on the blockchain shortly.',
        cancelable = false
    ): void => {
        setShowWallet(false)
        setShowResult(false)
        setShowProcessing(state)
        setProcessingDesc(processingText)
        setIsProcessingCancelable(cancelable)
    }

    const toggleWallet = (
        state: boolean,
        description: string,
        delegation = false,
        cancelable = false
    ): void => {
        setShowProcessing(false)
        setShowResult(false)
        setWalletDescription(description)
        setShowWallet(state)
        setIsWalletCancelable(cancelable)
        setIsDelegation(delegation)
    }
    const toggleResult = (
        state: boolean,
        title = '',
        description = '',
        resultType = ResultModalType.SUCCESS,
        errorDescComponent?: ReactElement,
        cancelable = true
    ): void => {
        setShowWallet(false)
        setShowProcessing(false)
        setErrorTitle(title)
        setErrorDesc(description)
        setErrorDescComponent(errorDescComponent)
        setShowResult((_prevIsOpen) => state)
        setIsErrorRecoverable(cancelable)
        setResultType(resultType)
        setIsNoButton(isNoButton)
    }

    return (
        <ModalContext.Provider
            value={{
                resultType,
                processingDesc,
                isErrorRecoverable,
                errorComponent,
                toggleWallet,
                showProcessing,
                showWallet,
                showResult,
                toggleProcessing,
                toggleResult,
                errorDesc,
                errorTitle,
                walletDescription,
                isProcessingCancelable,
                isWalletCancelable,
                showPersistentModal,
                isDelegation,
                hide,
                setModalTheme,
            }}
        >
            {React.createElement(Theme, null, [
                <ResultModal key="resultModal" />,
                <WalletModal key="walletModal" />,
                <ProcessingModal key="processingModal" />,
                children
            ])}
        </ModalContext.Provider>
    )
}
