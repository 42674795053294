import { Button, Grid } from '@mui/material'
import { Wallets } from '../blockchain/Utils'

const MetaMaskButton = ({ connect }: { connect: (event:any) => Promise<void> | void }) => {
    return (
        <Grid mt={2} container alignItems="center">
            <Grid item xs={12}>
                <Button
                    fullWidth
                    onClick={connect}
                    startIcon={Wallets.metamask.smallIcon}
                    key={'metamask'}
                    variant="contained"
                    color="secondary"
                >
                    Connect {Wallets.metamask.simpleName}
                </Button>
            </Grid>
        </Grid>
    )
}

export default MetaMaskButton
