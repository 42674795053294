import { Box, Grid, Typography } from '@mui/material'
import { type FC } from 'react'
interface CardUsageProps {
    cardUsageTitle: string | undefined
}

/**
 * This is a component that shows the card usage
 * @param {number} props.usages
 * @returns {JSX.Element}
 */
const CardUsage: FC<CardUsageProps> = ({ cardUsageTitle }) => {
    return (
        <Box
        sx={{
            bgcolor: 'warning.light',
          }}
          >
            <Grid alignItems={'center'} container padding={2}>
                <Grid item xs={2} />
                <Grid item xs={8} container>
                    <Grid item xs={12}>
                        <Typography
                            fontWeight={700}
                            align="center"
                            variant="body1"
                        >
                            {cardUsageTitle ?? ''}
                        </Typography>
                    </Grid>
                    <Grid mt={2} item xs={12}>
                        <Typography align="center" variant="body2">
                            We suggest you switch to automatic approval so you can save
                            on gas fees for future transactions.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={2} />
            </Grid>
        </Box>
    )
}

export default CardUsage
