import { SolanaProvider } from './solana-provider'
import * as web3 from '@solana/web3.js'
import {
    createApproveInstruction,
    createTransferInstruction,
    getAssociatedTokenAddress,
    TOKEN_PROGRAM_ID,
} from '@solana/spl-token'
import { getParsedAmount } from '../../../utils'
import {
    type SupportedToken,
    type BlockchainConfig,
    type Transaction,
} from '../../../types'
import { getAccountPath } from '../wallet-providers/ledger-wallet-provider'

// The only difference between this class and SolanaProvider is the approve function, so we extend it and override the approve function

export class SolanaLedger extends SolanaProvider {
    index: number
    constructor(provider: any, blockchainConfig: BlockchainConfig, index = 0) {
        super(provider, blockchainConfig)
        this.index = index
    }

    // @override
    async approve(
        amount: string,
        tokenType: SupportedToken
    ): Promise<Transaction> {
        const addressStr = await this.provider.publicKey
        const sourcePubkey = new web3.PublicKey(addressStr)

        const userAta = await getAssociatedTokenAddress(
            new web3.PublicKey(this.tokenContract[tokenType]),
            sourcePubkey
        )
        const decimals = this.decimals(tokenType)
        const parsedAmount = getParsedAmount(amount, decimals)
        const [pdaDelegate] = await web3.PublicKey.findProgramAddress(
            [],
            this.baanxAddress
        )

        const tx = new web3.Transaction().add(
            createApproveInstruction(
                userAta,
                pdaDelegate,
                sourcePubkey,
                parsedAmount
            )
        )

        const { blockhash } = await this.connection.getLatestBlockhash()
        tx.recentBlockhash = blockhash
        tx.feePayer = sourcePubkey

        // Serializing the transaction to pass it to Ledger Nano for signing
        const unsignedTx = tx.serializeMessage()

        // Sign with the Ledger Nano (Sign what you see)
        const { signature } = await this.provider.signTransaction(
            getAccountPath('sol', this.index),
            unsignedTx
        )
        tx.addSignature(sourcePubkey, signature)

        // Serialize the same transaction as before, but added the signature on it
        const signedTx = tx.serialize()

        // Sending the transaction to the blockchain
        const hash = await this.connection.sendRawTransaction(signedTx, {
            preflightCommitment: 'confirmed',
            skipPreflight: false,
        })

        return {
            hash,
        }
    }

    async payment(
        amount: string,
        tokenType: SupportedToken
    ): Promise<Transaction> {
        const addressStr = await this.provider.publicKey
        const sourcePubkey = new web3.PublicKey(addressStr)

        const userAta = await getAssociatedTokenAddress(
            new web3.PublicKey(this.tokenContract[tokenType]),
            sourcePubkey
        )
        const destAta = await getAssociatedTokenAddress(
            new web3.PublicKey(this.tokenContract[tokenType]),
            new web3.PublicKey(this.blockchainConfig.paymentAddress)
        )

        const decimals = this.tokenDecimals[tokenType]
        const parsedAmount = getParsedAmount(amount, decimals)

        const tx = new web3.Transaction().add(
            createTransferInstruction(
                userAta,
                destAta,
                sourcePubkey,
                parsedAmount,
                [],
                TOKEN_PROGRAM_ID
            )
        )

        const { blockhash } = await this.connection.getLatestBlockhash()
        tx.recentBlockhash = blockhash
        tx.feePayer = sourcePubkey

        // Serializing the transaction to pass it to Ledger Nano for signing
        const unsignedTx = tx.serializeMessage()

        // Sign with the Ledger Nano (Sign what you see)
        const { signature } = await this.provider.signTransaction(
            getAccountPath('sol', this.index),
            unsignedTx
        )
        tx.addSignature(sourcePubkey, signature)

        // Serialize the same transaction as before, but added the signature on it
        const signedTx = tx.serialize()

        // Sending the transaction to the blockchain
        const hash = await this.connection.sendRawTransaction(signedTx, {
            preflightCommitment: 'confirmed',
            skipPreflight: false,
        })

        const txFinal = {
            ...tx,
            hash,
            wait: async () => {
                const latestBlockHash =
                    await this.connection.getLatestBlockhash()
                await this.connection.confirmTransaction({
                    blockhash: latestBlockHash.blockhash,
                    lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
                    signature: hash,
                })
                return { hash }
            },
        }
        return txFinal
    }

    async signMessage(message: string): Promise<string> {
        const sign = await this.provider.signOffchainMessage(
            getAccountPath('sol', this.index),
            message
        )
        return sign.signature
    }
}
