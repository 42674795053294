import {
    Card,
    Grid,
    InputAdornment,
    TextField,
    Typography,
} from '@mui/material'
import { useState, type FC } from 'react'
import { Currencies } from '../blockchain/Utils'

interface CustomTextFieldProps {
    currency: string
    onChange: (amount: string) => void
}
const CustomTextField: FC<CustomTextFieldProps> = ({
    currency,
    onChange,
}) => {
    const [value, setValue] = useState('')

    return (
        <Grid mt={1} container>
            <Grid item xs={12}>
                <Card variant="outlined">
                    <TextField
                        fullWidth
                        sx={{
                            '.MuiOutlinedInput-root': {
                                borderRadius: '0.75rem',
                            },
                        }}
                        inputProps={{style: {fontSize: "1.25rem", fontWeight: 600}}} 
                        onChange={(e) => {
                            const inputValue = e.target.value
                            const isValidInput =
                                /^-?\d*\.?\d{0,6}$/.test(inputValue) &&
                                !inputValue.includes(',')
                            if (isValidInput) {
                                setValue(inputValue)
                                onChange(inputValue)
                            }
                        }}
                        placeholder='0.00'
                        variant="outlined"
                        value={value}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <Typography color="secondary" fontWeight="600">
                                        {Currencies[currency].name}
                                    </Typography>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Card>
            </Grid>
        </Grid>
    )
}

export default CustomTextField
