import type { Config, ConnectAddress } from '../../types'
import { useMutation, useQuery } from 'react-query'
import { type Client, useClient } from './client'

import { defaultMutationOptions } from './api-utils'

const getUserAddressSearchConfig = (client: Client, config: Config) => {
  return {
    queryKey: ['getUserAddresses'],
    queryFn: async () => await client(`${config.api.url}/addresses?active=true`, {}),
    refetchOnWindowFocus: false,
    enabled: false
  }
}

function useAddress (config: Config) {
  const client = useClient()

  const result = useQuery<ConnectAddress[]>(
      getUserAddressSearchConfig(client, config)
  )
  return {
    ...result
  }
}

function usePutPriorityList (config: Config) {
  const client = useClient()

  return useMutation(
      async (data: Array<{ id: string, priority: number }>) =>
          await client(`${config.api.url}/addresses/priority-list`, {
            method: 'PUT',
            data
          }),
      {
        ...defaultMutationOptions()
      }
  )
}

export { useAddress, usePutPriorityList }
