import type { WalletProvider } from '../config'
import TransportWebUSB from '@ledgerhq/hw-transport-webusb'
import Eth from '@ledgerhq/hw-app-eth'
import Solana from '@ledgerhq/hw-app-solana'
import { bufferToString } from '../../../utils'
import { type SupportedBlockchain } from '../../../types'

const LEDGER_PATH_ETH = "44'/60'/ACCOUNT_INDEX'/0/0"
const LEDGER_PATH_SOL = "44'/501'/ACCOUNT_INDEX'"

export const getAccountPath = (ledgerApp: 'eth' | 'sol', index: number) => {
    const finalPath = ledgerApp === 'eth' ? LEDGER_PATH_ETH : LEDGER_PATH_SOL
    return finalPath.replace('ACCOUNT_INDEX', `${index}`)
}
let ethApp: Eth
let solApp: Solana
const ledgerWalletProvider = (ledgerApp: 'eth' | 'sol'): WalletProvider => {
    let network: SupportedBlockchain
    let accountId: string = ''
    let isConnected: boolean = false
    let provider: any

    let savedIndex: number = -1
    const getLedgerApp = async (): Promise<Eth | Solana> => {
        const transport = await TransportWebUSB.create()
        if (ledgerApp === 'eth') {
            if (!ethApp) ethApp = new Eth(transport)
            ;(ethApp as any).transport = TransportWebUSB.create()
            return ethApp
        } else {
            if (!solApp) solApp = new Solana(transport)
            ;(solApp as any).transport = TransportWebUSB.create()

            return solApp
        }
    }
    const getPath = () =>
        ledgerApp === 'eth' ? LEDGER_PATH_ETH : LEDGER_PATH_SOL
    const getAddresses = async (init: number, end: number) => {
        const addresses: string[] = []
        const app: any = await getLedgerApp()
        app.transport = await TransportWebUSB.create()

        for (let i = init; i <= end; i++) {
            const { address } = await app.getAddress(
                getPath().replace('ACCOUNT_INDEX', `${i}`)
            )
            addresses.push(
                typeof address !== 'string' ? bufferToString(address) : address
            )
        }
        return addresses
    }

    const connectWallet = async (
        supportedBlockchain: SupportedBlockchain,
        _provider: any,
        index = 0
    ) => {
        if (isConnected && savedIndex === index) return

        const app: any = await getLedgerApp()
        app.transport = await TransportWebUSB.create()

        network = supportedBlockchain
        isConnected = true
        savedIndex = index
        const retrievedAccount = await app.getAddress(
            getPath().replace('ACCOUNT_INDEX', `${index}`)
        )
        accountId =
            typeof retrievedAccount.address === 'string'
                ? retrievedAccount.address
                : bufferToString(retrievedAccount.address)
        provider = { ...app, publicKey: accountId }
    }

    return {
        getNetwork: () => network,
        getAccountId: () => accountId,
        isConnected: () => isConnected,
        connectWallet,
        getProvider: () => provider,
        hasWalletProviderInstalled: () => true,
        setOnChangeHandler: () => {},
        getAddresses,
    }
}

export default ledgerWalletProvider
