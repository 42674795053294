import { useMutation, useQuery } from 'react-query'
import { type Client, useClient } from './client'
import type { WalletTokenDto, WalletTokenRequestDto } from '@baanx/domain/dto'

import { type Config, type UserData, type WalletData } from '../../types'
import { sharedProps, defaultMutationOptions } from './api-utils'
import { type SwapData } from '@baanx/domain'

const getTokenSearchConfig = (client: Client, token: string, config: Config) => {
  return {
    queryKey: ['getUserByToken'],
    queryFn: async () => await client(`${config.api.url}/auth-tokens/${token}`, {}),
    ...sharedProps
  }
}

const getSwapTokenSearchConfig = (client: Client, token: string, config: Config) => {
  return {
    queryKey: ['getSwapByToken'],
    queryFn: async () => await client(`${config.api.url}/swap-tokens/${token}`, {}),
    ...sharedProps

  }
}

const getWalletTokenSearchConfig = (client: Client, config: Config) => {
  return {
    queryKey: ['getWalletByToken'],
    queryFn: async () => await client(`${config.api.url}/wallet`, {}),
    ...sharedProps

  }
}

function useToken (config: Config, { token }: { token: string }) {
  const client = useClient()
  const result = useQuery<UserData>(getTokenSearchConfig(client, token, config))
  return {
    ...result
  }
}

function useSwapToken (config: Config, token: string) {
  const client = useClient()
  const result = useQuery<SwapData>(getSwapTokenSearchConfig(client, token, config))
  return {
    ...result
  }
}
function useWalletToken (config: Config) {
  const client = useClient()
  const result = useQuery<WalletData>(getWalletTokenSearchConfig(client, config))
  return {
    ...result
  }
}

function usePostWalletToken (config: Config) {
  const client = useClient()

  const result = useMutation(
    async (data) =>
      await client(`${config.api.url}/wallet`, {
        method: 'POST',
        data
      }),
    {
      ...defaultMutationOptions()
    }
  )
  // TODO is this ok? We want typed data but we don't want to have to cast it
  return result as unknown as { mutateAsync: (data: WalletTokenRequestDto) => Promise<WalletTokenDto> }
}

function useDeleteWalletToken (config: Config) {
  const client = useClient()

  return useMutation(
    async () =>
      await client(`${config.api.url}/wallet`, {
        method: 'DELETE'
      }),
    {
      ...defaultMutationOptions()
    }
  )
}

export { useToken, useSwapToken, useWalletToken, usePostWalletToken, useDeleteWalletToken }
