import './App.css'
import { BrowserRouter } from 'react-router-dom'
import AppRouter from './AppRouter'
import { QueryClient, QueryClientProvider } from 'react-query'
import ModalProvider from './contexts/ModalContext'
import * as Sentry from '@sentry/react'
import ErrorFallback from './components/error/ErrorFallback'
import config from './config'
import { FoxTheme } from './themes/FoxTheme'
import CustomMetaMaskProvider from './components/metamask/CustomMetaMaskProvider'
import { version } from '../package.json'

console.log('config: ', config)

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
        },
        mutations: {},
    },
})

Sentry.init({
    environment: config.sentry.environment,
    release: version,
    enabled: true,
    dsn: config.sentry.dsn,
    tracesSampleRate: 1.0,
})
const logError = (error: Error, componentStack: string, eventId: string) => {
    console.error('error: ', error)
    console.error('eventId: ', eventId)
    console.error('stack:', componentStack)
}

function App(): JSX.Element {
    return (
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <CustomMetaMaskProvider>
                    <FoxTheme>
                            <ModalProvider>
                                <div className="App">
                                    <Sentry.ErrorBoundary
                                        fallback={ErrorFallback}
                                        onError={logError}
                                    >
                                        <AppRouter />
                                    </Sentry.ErrorBoundary>
                                </div>
                            </ModalProvider>
                    </FoxTheme>
                </CustomMetaMaskProvider>
            </BrowserRouter>
        </QueryClientProvider>
    )
}

export default App
