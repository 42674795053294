import { ethers } from 'ethers'
import bs58 from 'bs58'
import { Buffer } from 'buffer'

export const getParsedAmount = (amount: string, decimals: number) => {
    return ethers.parseUnits(amount, decimals)
}

export const bufferToString = (buffer): string => {
    const hex = buffer.toString('hex')
    const base58 = bs58.encode(Buffer.from(hex, 'hex'))
    return base58
}

export const formatBalance = (
    amount: string = '0',
    minimumFractionDigits = 2
) => {
    return parseFloat(amount).toLocaleString('en-US', {
        minimumFractionDigits,
    })
}
