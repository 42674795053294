import { Dialog, Grid, IconButton, Typography, Card, Chip } from '@mui/material'
import { ReactComponent as Wallet } from '../../assets/modalwallet.svg'
import { useModal } from '../../contexts/ModalContext'
import CloseSVG from '@mui/icons-material/Close'
import { type ReactElement } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import InfoIcon from '@mui/icons-material/Info'

const CheckCircleColored = (): ReactElement => {
    return <CheckCircleIcon sx={{ color: '#ffbb00' }} />
}

const WalletModal = (): ReactElement => {
    const {
        showWallet: open,
        walletDescription: description,
        toggleWallet,
        isWalletCancelable,
        isDelegation
        } = useModal()
    const handleClose = (): void => {
        isWalletCancelable && toggleWallet(false, '')
    }

    return (
        <Dialog open={open} onClose={handleClose} disableAutoFocus={true}>
            <Grid container spacing={2} textAlign={'center'} p={4}>
                <Grid item container xs={12}>
                    <Grid item xs={11} />
                    <Grid item xs={1}>
                        {isWalletCancelable && (
                            <IconButton color="inherit" onClick={handleClose}>
                                <CloseSVG />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Wallet /> 
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h4" fontWeight={400}>Go to your wallet</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="body1" px={2}>
                        {description}
                    </Typography>
                </Grid>

                {isDelegation && (
                    <Grid container>
                        <Grid container item xs={12}>
                            <Grid
                                container
                                justifyContent={'space-evenly'}
                                item
                                xs={12}
                                mt={2}
                            >
                                <Grid item xs={12} md={5}>
                                    <Card
                                        variant="outlined"
                                        sx={{ minWidth: 245 }}
                                    >
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            p={1}
                                            pb={3}
                                        >
                                            <Chip
                                                size="small"
                                                sx={{ visibility: 'hidden' }}
                                            />
                                            <Grid item xs={12} mt={1}>
                                                <Typography variant="h5">
                                                    &apos;Max&apos;
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                alignItems={'center'}
                                                justifyContent={'left'}
                                                ml={1}
                                            >
                                                <Grid item>
                                                    <InfoIcon />
                                                </Grid>
                                                <Grid item ml={0.5}>
                                                    <Typography variant="body2">
                                                        Future approvals
                                                        required
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                alignItems={'center'}
                                                justifyContent={'left'}
                                                ml={1}
                                            >
                                                <Grid item>
                                                    <InfoIcon />
                                                </Grid>
                                                <Grid item ml={0.5}>
                                                    <Typography variant="body2">
                                                        Pay gas on every
                                                        adjustment
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                                <Grid item color="black" xs={12} md={5}>
                                    <Card
                                        color="black"
                                        variant="outlined"
                                        sx={{
                                            backgroundColor: '#fff5d9',
                                            minWidth: 245,
                                        }}
                                    >
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            justifyContent="center"
                                            p={1}
                                            pb={3}
                                        >
                                            <Chip
                                                sx={{
                                                    backgroundColor: '#ffbb00',
                                                }}
                                                size="small"
                                                label={
                                                    <Typography
                                                        variant="overline"
                                                        fontWeight="bold"
                                                    >
                                                        Recommended
                                                    </Typography>
                                                }
                                            />
                                            <Grid item xs={12} mt={1}>
                                                <Typography
                                                    variant="h5"
                                                    fontWeight={'bold'}
                                                >
                                                    &apos;Use default&apos;
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                alignItems={'center'}
                                                justifyContent={'left'}
                                                ml={1}
                                            >
                                                <Grid item>
                                                    <CheckCircleColored />
                                                </Grid>
                                                <Grid item ml={0.5}>
                                                    <Typography variant="body2">
                                                        Only approve once
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                alignItems={'center'}
                                                justifyContent={'left'}
                                                ml={1}
                                            >
                                                <Grid item>
                                                    <CheckCircleColored />
                                                </Grid>
                                                <Grid item ml={0.5}>
                                                    <Typography variant="body2">
                                                        Save on future gas fees
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            item
                            xs={12}
                            mt={3}
                            justifyContent={'center'}
                        >
                            <Grid>
                                <Typography
                                    variant="body1"
                                    fontWeight="bold"
                                    mb={1}
                                >
                                    No matter your choice, enjoy these benefits:
                                </Typography>
                            </Grid>

                            <Grid container item alignItems={'center'} xs={11}>
                                <Grid item>
                                    <CheckCircleColored />
                                </Grid>
                                <Grid item ml={1}>
                                    <Typography variant="body1">
                                        Immutable contract with multiple audits
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item alignItems={'center'} xs={11}>
                                <Grid item>
                                    <CheckCircleColored />
                                </Grid>
                                <Grid item ml={1}>
                                    <Typography variant="body1">
                                        Adjust your spending cap anytime
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Dialog>
    )
}

export default WalletModal
