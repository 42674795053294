import { Button, Grid } from '@mui/material'

const ErrorFallback = ({ error, resetError }) => {
    return (
        <div
            style={{
                width: '100vw',
                height: '100vh',
                backgroundColor: 'white',
                overflowY: 'scroll',
            }}
        >

            <Grid
                sx={{
                    backgroundColor: 'white',
                    height: '100%',
                    color: 'black',
                    padding: '2vw',
                    marginTop: '5rem',
                    justifyContent: 'center',
                    textAlign: 'center',
                    alignContent: 'center',
                    fontSize: '1.6rem',
                }}
            >
                <Grid item xs={6}>
                    <p>Something went wrong</p>
                </Grid>
                <Grid item xs={6} sx={{ fontSize: '1rem' }}>
                    {' '}
                    <p>{error?.message || error?.statusText}</p>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" onClick={resetError}>
                        Try again
                    </Button>
                </Grid>
            </Grid>
        </div>
    )
}

export default ErrorFallback
