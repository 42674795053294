// DO NOT forget to update index.html with the new environment variables
const windowEnv = (window as unknown as {
  VITE_ENVIRONMENT
  VITE_BLOCKCHAIN_CONFIG_KEY
  VITE_API_URL
  VITE_DOMAIN
  VITE_REDIRECT_LINK
  VITE_SENTRY_DSN
})

const environment = import.meta.env.VITE_ENVIRONMENT ?? windowEnv.VITE_ENVIRONMENT
const blockchainConfigKey = import.meta.env.VITE_BLOCKCHAIN_CONFIG_KEY ?? windowEnv.VITE_BLOCKCHAIN_CONFIG_KEY

const config = {
  environment,
  blockchainConfigKey,
  domain: import.meta.env.VITE_DOMAIN ?? windowEnv.VITE_DOMAIN,
  redirectLink: import.meta.env.VITE_REDIRECT_LINK ?? windowEnv.VITE_REDIRECT_LINK,
  swapRedirectLink: import.meta.env.VITE_SWAP_REDIRECT_LINK ?? 'https://dev.abbaanx.com/anrk-app-mock', // WARNING TODO
  app: {
    port: import.meta.env.VITE_PORT || 3000,
    https: import.meta.env.VITE_HTTPS || false
  },
  api: {
    url: import.meta.env.VITE_API_URL ?? windowEnv.VITE_API_URL
  },
  sentry: {
    dsn: import.meta.env.VITE_SENTRY_DSN ?? windowEnv.VITE_SENTRY_DSN,
    environment: environment === 'production' ? blockchainConfigKey : 'local',
    tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE || 1.0
  }
}

export default config
