import { Card, CardActionArea, Grid, Typography } from '@mui/material'
import { Blockchains, Currencies } from '../blockchain/Utils'
import { SupportedBlockchain } from '@baanx/blockchain-config'
import ArrowDown from '@mui/icons-material/KeyboardArrowDown'

import { fiatSymbol, isInfinite } from '../../utils'
import SpendingLimitChip from '../spending/spending-limit-chip'
import { formatBalance } from '@baanx/common/utils'

import { isMobile } from 'react-device-detect'
interface WalletBalanceProps {
    fiatBalance: string
    coinHandler: () => void
    tokenBalance: string
    selectedCurrency: string
    preferredFiatCurrency: string
    allowance: string
    isWarning?: boolean
}
const WalletBalance = ({
    fiatBalance,
    coinHandler,
    tokenBalance,
    selectedCurrency,
    preferredFiatCurrency,
    allowance,
    isWarning,
}: WalletBalanceProps) => (
    <Card
        sx={{ borderColor: isWarning ? 'warning.main' : 'defaultBorderColor' }}
        variant="outlined"
    >
        <CardActionArea onClick={coinHandler}>
            <Grid pr={2} pl={2} pt={2} pb={2} container>
                <Grid item alignContent="center" xs={'auto'} mr={1}>
                    {Currencies[selectedCurrency].icon}
                </Grid>

                <Grid item xs>
                    <Grid item xs={3}>
                        <Typography
                            fontWeight={700}
                            fontSize="0.875rem"
                            variant="body1"
                        >
                            {Currencies[selectedCurrency].name}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption">
                            {Blockchains[SupportedBlockchain.LINEA].name}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item xs>
                    <Grid
                        container
                        direction={'column'}
                        alignContent={'end'}
                        pr={1}
                    >
                        <Typography
                            noWrap
                            fontWeight={700}
                            fontSize="0.875rem"
                            variant="caption"
                        >
                            {`${tokenBalance} ${Currencies[selectedCurrency].name}`}
                        </Typography>
                        <Typography variant="caption">
                            {fiatSymbol[preferredFiatCurrency] || ''}{' '}
                            {fiatBalance}{' '}
                            {preferredFiatCurrency?.toUpperCase() ?? ''}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item xs={'auto'} textAlign="end" alignContent="center">
                    <ArrowDown />
                </Grid>
                <Grid container ml={4}>
                    <SpendingLimitChip
                        text={
                            isInfinite(allowance)
                                ? (isMobile? 'Automatic approval' : 'Account has automatic approval for this token')
                                : `Current spending limit: ${formatBalance(
                                      allowance
                                  )} ${Currencies[selectedCurrency].name}`
                        }
                    ></SpendingLimitChip>
                </Grid>
            </Grid>
        </CardActionArea>
    </Card>
)

export default WalletBalance
