import { useMutation } from 'react-query'
import { useClient } from './client'
import { defaultMutationOptions } from './api-utils'
import { type Config } from '../../types'

const approvalEndpoint = {
  path: '/approvals',
  method: 'POST'
}

function useAudit (config: Config, { activateAddress } = { activateAddress: true }) {
  const client = useClient()

  return useMutation(
    async (data) =>
      await client(`${config.api.url}${approvalEndpoint.path}?activateAddress=${activateAddress}`, {
        method: approvalEndpoint.method,
        data
      }),
    {
      ...defaultMutationOptions()
    }
  )
}

export { useAudit }
