import {
    Card,
    CardActionArea,
    Chip,
    Grid,
    Radio,
    Typography,
} from '@mui/material'
import CustomTextField from '../input/custom-text-field'

interface DelegateOptionsProps {
    isContinuousApproval: boolean
    onContinuousSelected: (option: boolean) => void
    onChangeAmount: (amount: string) => void
    currency: string
}
interface RadioCardProps {
    onClick: () => void
    checked: boolean
    children: any
}
const RadioCard = ({ onClick, checked, children }:RadioCardProps) => {
    return (
        <Card
            sx={{
                borderColor: checked ? 'primary.main' : 'none',
                backgroundColor: 'secondary.light',
            }}
            variant="outlined"
        >
            <CardActionArea onClick={onClick}>
                <Grid container>
                    <Grid item xs={'auto'} padding={1}>
                        <Radio checked={checked} name="radio-buttons" />
                    </Grid>

                    <Grid
                        xs
                        container
                        item
                        alignContent="center"
                        padding={2}
                    >
                        <Grid item>
                            <Typography fontWeight={700} variant="body1">
                                {children[0]}
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="caption" color='secondary'>
                                {children[1]}
                            </Typography>
                        </Grid>
                        {children[2] && children[2]}
                    </Grid>
                </Grid>
            </CardActionArea>
        </Card>
    )
}

// TODO refactor this properly
const DelegateOptions = ({
    isContinuousApproval,
    onContinuousSelected,
    onChangeAmount,
    currency,
}: DelegateOptionsProps) => {
    return (
        <Grid item rowGap={"1.5rem"} container xs={12}>
            <Grid item xs={12}>
                <RadioCard
                    onClick={() => {
                        onContinuousSelected(true)
                    }}
                    checked={isContinuousApproval}
                >
                    {'Automatically approve spending'}
                    {[
                        "This means you won't have to approve each",
                        'transaction or pay gas fees to increase your',
                        'spending cap in the future.',
                    ].join(' ')}
                    <Grid container mt={1} alignContent={'center'} item xs={11}>
                        <Grid item xs={10}>
                            <Chip
                                color="primary"
                                label="Recommended"
                                size="small"
                            />
                        </Grid>
                    </Grid>
                </RadioCard>
            </Grid>

            <Grid item xs={12}>
                <RadioCard
                    onClick={() => {
                        onContinuousSelected(false)
                    }}
                    checked={!isContinuousApproval}
                >
                    {'Approve a specific limit'}
                    {[
                        'This means you’ll have to pay a gas fee to',
                        'increase your total spending limit if you',
                        'want to keep using your card beyond the',
                        'specific amount you set here.',
                    ].join(' ')}
                    {!isContinuousApproval && (
                        <CustomTextField
                            currency={currency}
                            onChange={onChangeAmount}
                        />
                    )}
                </RadioCard>
            </Grid>
        </Grid>
    )
}

export default DelegateOptions
