export const defaultMutationOptions = (options = {}) => ({
  onError: (_err: string, _variables: any, recover: unknown) =>
    typeof recover === 'function' ? recover() : null,
  ...options
})

export const sharedProps = {
  refetchOnWindowFocus: false,
  enabled: false
}
