import { Button, Grid, IconButton, Stack, Typography } from '@mui/material'
import Arrow from '@mui/icons-material/ArrowForwardIos'
import ArrowBack from '@mui/icons-material/ArrowBack'
import React from 'react'

import { Currencies } from '../blockchain/Utils'
import { type SupportedToken, type SupportedBlockchain } from '@baanx/blockchain-config'

interface CoinSelectorProps {
    setSelectedCurrency: (coin?: any) => void
    coins: SupportedToken[]
    selectedNetwork: SupportedBlockchain
}

const CoinSelector: React.FC<CoinSelectorProps> = ({
    coins,
    setSelectedCurrency,
    selectedNetwork
}) => (
        <Grid alignItems="center" container>
            <Grid container alignItems="center">
                <Grid item xs={1}>
                <IconButton
                    size="large"
                    onClick={() => {
                        setSelectedCurrency(null)
                    }}
                >
                    <ArrowBack />
                </IconButton>
                </Grid>
                <Grid item xs={10}>
                    <Typography align="center" variant="h5">
                        Select a currency
                    </Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                
            </Grid>
            <Grid container p={2} item xs={12}>

                <Grid item xs={12}>
                    <Stack spacing={1} marginTop={2}>
                        {selectedNetwork != null &&
                            coins.map((key) => {
                                return (
                                    <Button
                                        key={key}
                                        onClick={() => {
                                            setSelectedCurrency(
                                                key
                                            )
                                        }}
                                        variant="contained"
                                        fullWidth
                                        startIcon={
                                            Currencies[key]
                                                .icon
                                        }
                                        endIcon={<Arrow />}
                                    >
                                        {Currencies[key].name}
                                    </Button>
                                )
                            })}
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    )

export default CoinSelector
