import { ethers } from 'ethers'
import bs58 from 'bs58'
import { Buffer } from 'buffer'
/**
 * Solana uses the number 2199023255551999755 as a default value for the "maximum allowance" (which is the maximum value for a u64).
 * We can use it also for Ethereum without having problems with ethers parse/format units.
 **/
export const ALLOWANCE_MAX_VALUE = '2199023255551'
const LOWER_INFINITE_SPENDING_LIMIT = BigInt('2099023255551')

// As the allowance can change in the future, we need to check if the value is infinite by considering as infinite a lower value
export const isInfinite = (value: string) => BigInt(Math.floor(+value)) >= LOWER_INFINITE_SPENDING_LIMIT
export const getParsedAmount = (amount: string, decimals: number) => {
    return ethers.parseUnits(amount, decimals)
}
export const bufferToString = (buffer): string => {
    const hex = buffer.toString('hex')
    const base58 = bs58.encode(Buffer.from(hex, 'hex'))
    return base58
}

export const formatBalance = (
    amount: string,
    minimumFractionDigits = 2
) => {
    if (Number.isNaN(parseFloat(amount))) {
        return '-'
    }
    return parseFloat(amount).toLocaleString('en-US', {
        minimumFractionDigits,
    })
}

export const fiatSymbol = {
    'usd': '$', 
    'eur': '€',
    'gbp': '£',
}