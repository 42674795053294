import { Card, CardActionArea, Grid, Typography } from '@mui/material'
import ArrowForward from '@mui/icons-material/KeyboardArrowRight'

interface AccountSwitchProps {
    connectedAccount: string
    switchAccountHandler: () => Promise<void>
}
const AccountSwitch = ({
    connectedAccount,
    switchAccountHandler,
}: AccountSwitchProps) => (
    <Grid item xs={12}>
        <Card variant="outlined">
            <CardActionArea onClick={switchAccountHandler}>
                <Grid container p={2}>
                    <Grid item container xs={11}>
                        <Grid item xs={12}>
                            <Typography fontWeight="700" variant="body1">
                                Account
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="caption"
                                sx={{ wordWrap: 'break-word' }}
                            >
                                {connectedAccount}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={1} textAlign="end" alignContent="center">
                        <ArrowForward />
                    </Grid>
                </Grid>
            </CardActionArea>
        </Card>
    </Grid>
)

export default AccountSwitch
