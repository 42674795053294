import { useEffect, useState } from 'react'
import { useApp } from '../../hooks/useApp'
import {
  AppMode, SUPPORTED_SWAP_BLOCKCHAINS, type SupportedBlockchain,
} from '../../types'
import ConnectUserWallet from '../../components/connect/ConnectUserWallet'
import { useLocation } from 'react-router-dom'
import { useModal } from '../../contexts/ModalContext'
import UIContainer from '../../components/layout/UIContainer'
import { PostErrorMessage, PostOperationType } from '../../error'
import usePostMessage from '../../hooks/usePostMessage'

const ConnectWallet = () => {
  const { setAppMode, setSelectedNetwork, appMode, selectedNetwork, setSelectedWallet, blockchain } = useApp()
  const location = useLocation()
  const { showPersistentModal } = useModal()
  const [isLoaded, setLoaded] = useState(false)
  const blockchainUrl = new URLSearchParams(location.search).get('blockchain') as string ?? 'ethereum'
  const { postErrorMessage } = usePostMessage(
    PostOperationType.WALLET_CONNECT
)
  useEffect(() => {
    setAppMode(AppMode.CONNECT_WALLET)
    if (!blockchainUrl) {
      postErrorMessage(PostErrorMessage.WALLET_NO_NETWORK)
      return
    } 
    if (!SUPPORTED_SWAP_BLOCKCHAINS.includes(blockchainUrl as SupportedBlockchain)) {
      postErrorMessage(PostErrorMessage.WALLET_INCOMPATIBLE_NETWORK)
      return
    }

    setSelectedNetwork(blockchainUrl as SupportedBlockchain) // TODO use a reverse enum map. Forcefully cast defeats the purpose of using enums
    setLoaded(true)
  }, [setAppMode, location.search, setSelectedNetwork, showPersistentModal, postErrorMessage, blockchainUrl])

  return (isLoaded &&
    <UIContainer>
      <ConnectUserWallet selectedNetwork={selectedNetwork} setSelectedWallet={setSelectedWallet} blockchain={blockchain} appMode={appMode} />
    </UIContainer>
  ) || <></>
}

export default ConnectWallet
