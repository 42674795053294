import type { WalletProvider } from '../config'
import { isMobile, browserName } from 'react-device-detect'
import { SupportedBlockchain } from '../../../types'
const solWalletProvider = (): WalletProvider => {
    let network: SupportedBlockchain
    let accountId: string = ''
    let isConnected: boolean = false
    let provider: any

    const connectWallet = async (
        _network: SupportedBlockchain = SupportedBlockchain.SOLANA,
        browserProvider: any
    ) => {
        if (!browserProvider) throw Error('Solana provider not available!')
        provider = browserProvider
        await provider.connect()
        network = _network
        accountId = provider.publicKey?.toString() as string
        isConnected = provider.isConnected
    }
    const hasWalletProviderInstalled = () => {
        const windowAsAny: any = window
        // if mobile allow phantom wallet app. The last condition is to ignore Opera Wallet since it injects window.solana.isPhantom but it is not working
        if (
            isMobile &&
            browserName !== 'Chrome WebView' &&
            !(browserName !== 'Opera' && windowAsAny.solana?.isPhantom)
        ) {
            return false
        }

        return windowAsAny.solana
    }

    const setOnChangeHandler = (refreshFn: () => void) => {
        if (provider.isPhantom) {
            provider.on('accountChanged', (newAccount) => {
                if (!newAccount) return
                refreshFn()
            })
        } else {
            setInterval(() => {
                if (provider.publicKey !== accountId) refreshFn()
            }, 1000)
        }
    }

    return {
        getNetwork: () => network,
        getAccountId: () => accountId,
        isConnected: () => isConnected,
        connectWallet,
        getProvider: () => provider,
        hasWalletProviderInstalled,
        setOnChangeHandler,
    }
}

export default solWalletProvider
